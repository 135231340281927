
import React from "react";
import {
  CubeIcon,
  CodeBracketIcon,
  CursorArrowRaysIcon,
  VariableIcon,
  TruckIcon,
  MapPinIcon,
} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import auto from "../assets/partners/autologo.png"
import fcg from "../assets/partners/fcglogo.png"
import coach from "../assets/partners/coachlogo.png"
import risk from "../assets/partners/risklogo.png"
import speed from "../assets/partners/speedlogo.png"
import today from "../assets/partners/todaylogo.png"



function ClientsSection(props) {
  const clients = [
    {
      name: "Foster Cannon Group",
      logo: fcg,
      description: "The goal of the Foster Cannon Group is to escalate Agents and their Team Members' performance and confidence in selling life insurance. Proven needs based techniques will be shared to educate attendees on how to establish and sustain an ongoing Million Dollar Round Table level of production. Our Agency Coaching will cover five areas of proven methods to help Agents and Team Members refine their skills and take their performance to the next level.",
      url: "https://fostercannongroup.com"
    },
    {
      name: "SPEED Training",
      logo: speed,
      description: "SPEED Training is a 1 day workshop focused on using Technology and Systems to run an efficient insurance agency. We combine multiple strategies from email templates (over 60 custom email templates are included), BOD, books, corporate training, and more to show you how to create an outstanding customer experience without a ton of extra effort.",
      url: "https://speedtrainingworkshop.com"
    },
    {
      name: "TodayApp",
      logo: today,
      description: "The TodayApp Pro is your agency's all in one script-focused production, activity, and team management software. A platform that includes pre-loaded word tracks for both producers and customer care team members, commission calculation, production and activity tracking. The TodayApp delivers an easy, intuitive and personalized experience via web browser or mobile device. Focus your team on the goals of your agency with this hightly customizeable system designed to make you execute better on your systems and processes.",
      url: "https://todayapppro.com"
    },
    {
      name: "Risk Advisor Institute",
      logo: risk,
      description: 'The Risk Advisor institute offers on-demand and live training on "How to Become a Trusted Risk Advisor and turn your agency into an Automatic Marketing Machine." Key concepts include: The best/fastest way to position you and your agency as a Trusted Risk Advisor, How to Weave the most powerful Client Attraction Stories, and How to conduct the "Your World" conversation. Agencies that master these concepts clost more sales, are more respected, and get more referrals than they ever imagined.',
      url: "https://riskadvisorinstitute.com"
    },
    {
      name: "Coach P Consulting",
      logo: coach,
      description: "Looking for a program that actually forces action after learning great information? Coach P it is! Led weekly by a multi-line President's Club agent, Coach P has weekly training calls every Tuesday and Thursday on Zoom. For only $250/mo, you will receive behind the scenes access and training from one of the top performing agencies in the country. Weekly calls for the agent and team, monthly Agent only calls, and on-demand access to the video library.",
      url: "https://coachpconsulting.com"
    },
    {
      name: "Autopilot Recruiting",
      logo: auto,
      description: "Autopilot Recruiting is a continuous recruiting service where you'll be assigned a recruiter that has been trained to recruit on your behalf every business day year roudn. This recruiter will take over your CareerPlug, and will do everything from the assessments, to the phone interview, some video interviews, and will coordinate your in-office interviews. With this continuous activity over time you should never have to worry about having capacity issues, having the wrong people in the wrong seats, or being in a position where you can't hold your team accountable.",
      url: "https://autopilotrecruiting.com"
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 text-center text-green-100">
          {clients.map((client, index) => (
            <a href = {client.url} target="_blank" rel="noopener noreferrer">
            <div
              key={index}
              className="group h-24 rounded rounded-lg flex flex-col items-center justify-start relative bg-black bg-opacity-20 skew-x-2 inset-0 overflow-hidden hover:h-full transition-all duration-700"
            >
              <div className="relative inline-flex items-center space-x-2 text-2xl font-semibold p-4">
                <img src={client.logo} className="inline-block -skew-x-2 h-14 rounded-lg"></img>
                <span>{client.name}</span>
              </div>
              <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <p className="text-green-100 my-4 px-4">{client.description}</p>
              </div>
            </div>
            </a>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default ClientsSection;
