import React from "react";
import { useRouter } from "../../util/router";
import { useAuth } from "../../util/auth";
import { Link } from '../../util/router';
import SideNav from "./SideNav";
import TeamCard from "./TeamCard";
import PersonCard from "./PersonCard";
import AddMemberCard from "./AddMemberCard"
import { useInvitationsBySender, useTeamsByMembership } from "../../util/db";
import CreateTeamCard from "./CreateTeamCard";
import { HomeIcon, ChevronRightIcon } from '@heroicons/react/24/solid';


function TeamsSection(props) {
  const auth = useAuth();
  const router = useRouter()

  const {
    data: ownedTeams,
    error: ownedTeamsError,
    status: ownedTeamsStatus
  } = useTeamsByMembership(auth.user.uid)

  const {
    data: invitations,
    error: invitationsError,
    status: invitationsStatus
  } = useInvitationsBySender(auth.user.uid)

  function calculateAvailableSeats(teamArray, invitations) {
    var temp = teamArray.map(team => team.members).flat()
    var available = auth.user.stripeQuantity?auth.user.stripeQuantity*10:10

    var unique = [... new Set(temp)]
    

    return available-(unique.length + invitations.length)
  }


  return (
    <>
        <div className="container">
            {(router.query.id === "all")?
              <div className="flex flex-wrap justify-start gap-4 p-4">
                {(ownedTeamsStatus==="success" && ownedTeams && ownedTeams.length>0)&&(
                  ownedTeams.map((each, k) => { 
                    return <TeamCard team = {each} key = {k}/>
                  })
                )}
                <CreateTeamCard/>
              </div>
              :
            <>
            <div className="pl-4 flex flex-row items-center">
              <Link to ="/dashboard/dashboard">
                <HomeIcon className="text-gray-400 h-6"/> 
              </Link>
              <ChevronRightIcon className="px-4 text-gray-400 h-6"/> 
              <Link to ="/teams/all">
              <p className="text-sm text-gray-400">All Teams</p>
              </Link>
              <ChevronRightIcon className="px-4 text-gray-400 h-6"/> 
              <div>
                {(ownedTeams && ownedTeamsStatus === "success" &&ownedTeams.length>0)&&(
                  <>
                  <p className="text-lg font-semibold">{ownedTeams.find(each=> each.id ===router.query.id).name}</p>
                  <p className="text-sm text-gray-400">{ownedTeams.find(each=> each.id ===router.query.id).members.length + " Member(s)"}</p>
                  </>
                )}
              </div>
            </div>
            
            <div className="flex flex-wrap justify-center lg:justify-start gap-4 p-4">
            {(ownedTeamsStatus==="success" && ownedTeams && ownedTeams.length>0 && invitationsStatus === "success")&&(
            <>
              {ownedTeams.find(each=> each.id ===router.query.id).members.map((member, k) => { return <PersonCard
              owner={ownedTeams.find(each => each.id === router.query.id).owner}
              teamId = {router.query.id}
              key={k} 
              uid = {member} 
              teamMembers = {ownedTeams.find(each => each.id === router.query.id).members} 
              selfUid = {auth.user.uid}/>})}
              {(ownedTeams.find(each => each.id === router.query.id).owner === auth.user.uid)&&<AddMemberCard count = {calculateAvailableSeats(ownedTeams, invitations)} team = {ownedTeams.find(each => each.id === router.query.id)}/>}
            </>
            )}


            </div>
            </>}
            
      </div>
      <SideNav current = "teams"></SideNav>

    </>

  );
}

export default TeamsSection;
