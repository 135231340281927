// Map our custom plan IDs ("basic", "premium", etc) to Stripe price IDs
const stripePriceIds = {
  sby: process.env.REACT_APP_STRIPE_PRICE_SBY,
  sbm: process.env.REACT_APP_STRIPE_PRICE_SBM,
  sbm1: process.env.REACT_APP_STRIPE_PRICE_SBM1,

  tp: process.env.REACT_APP_STRIPE_PRICE_TP,
  tpm: process.env.REACT_APP_STRIPE_PRICE_TPM,
  tpl: process.env.REACT_APP_STRIPE_PRICE_TPL,
  proc: process.env.REACT_APP_STRIPE_PRICE_PROC,
  procm: process.env.REACT_APP_STRIPE_PRICE_PROCM,
  life: process.env.REACT_APP_STRIPE_PRICE_LIFE,
  shuff: process.env.REACT_APP_STRIPE_PRICE_SHUFF,


  proc1: process.env.REACT_APP_STRIPE_PRICE_PROC1,
  proc2: process.env.REACT_APP_STRIPE_PRICE_PROC2,
  proc3: process.env.REACT_APP_STRIPE_PRICE_PROC3,
  proc4: process.env.REACT_APP_STRIPE_PRICE_PROC4,
  proc5: process.env.REACT_APP_STRIPE_PRICE_PROC5,
  proc6: process.env.REACT_APP_STRIPE_PRICE_PROC6,
  proc7: process.env.REACT_APP_STRIPE_PRICE_PROC7,

  tp1: process.env.REACT_APP_STRIPE_PRICE_TP1,
  tp2: process.env.REACT_APP_STRIPE_PRICE_TP2,
  tp3: process.env.REACT_APP_STRIPE_PRICE_TP3,
  tp4: process.env.REACT_APP_STRIPE_PRICE_TP4,
};

const keyNames = {
  sby: "Million Dollar Stairs and Buckets Start-Up",
  sbm: "The Million Dollar Stairs and Buckets Start-Up Monthly",
  sbm1: "The Million Dollar Stairs and Buckets Start-Up Monthly",
  proc: "Million Dollar Tool Kit",
  tp: "Million Dollar Road Map",
  procm: "Million Dollar Tool Kit Monthly",
  tpm: "Million Dollar Road Map Monthly",
  life: "Million Dollar Membership",
  shuff: "Service Webinars",

  proc1: "Sales Process One: Medicare",
  proc2: "Sales Process Two: Premium Life",
  proc3: "Sales Processs Three: Finding The Money",
  proc4: "Sales Process Four: Protect & Participate",
  proc5: "Sales Process Five: Understanding Money",
  proc6: "Sales Process Six: Liability Wall",
  proc7: "Medicare Slide Show",

  tp1: "Million Dollar Manual",
  tp2: "Staff Handbook",
  tp3: "Paychecks Never Lie",
  tp4: "Bigger Better Paychecks",
}

// Get Stripe priceId
export function getStripePriceId(planId) {
  return stripePriceIds[planId];
}

// Get friendly plan ID ("basic", "premium", etc) by Stripe plan ID
// Used in auth.js to include planId in the user object
export function getFriendlyPlanId(stripePriceId) {
  return Object.keys(stripePriceIds).find(
    (key) => stripePriceIds[key] === stripePriceId
  );
}

export function getPlanName(planId) {
  return keyNames[planId];
}