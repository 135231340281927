import React from 'react';
import VideoCard from './VideoCard';
import { useState } from 'react';
import { useCodeOnce, updateCode, useVideoByIndex } from '../../util/db';
import { useRouter } from '../../util/router';

const ClientViewSection = () => {
  const router = useRouter()
  const [hasStarted, setHasStarted] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState({})
  const [disabled, setDisabled] = useState(false)

  const {
    status: codeStatus,
    data: code,
    error: codeError,
  } = useCodeOnce(router.query.code)

  const {
    status: videosStatus,
    data: videos,
    error: videosError,
  } = useVideoByIndex(0)


  function checkExpiration(time) {
    var isValid = false
    const currentTime = new Date().getTime();
    if (currentTime - time*1000 < 60*60*24*1000) {
      isValid = true
    } 
    return isValid
  }

  async function beginVideo() {
    setSelectedVideo( videos.find(video => video.language===code.language))
    setDisabled(true)
    await updateCode(code.id, 
    {
      language: code.language,
      method: code.method,
      opened: true,
      owner: code.owner,
      recipients: code.recipients,
      views: code.views?code.views +1: 1
    }
    )
    setHasStarted(true)
  }

  return (
    <>
    {(codeStatus === "success" && code !== null&& videosStatus==="success" && videosStatus.length>0)&&(
    <>
    {(checkExpiration(code.createdAt.seconds))?(
      <>
      <div className='container mt-4'>
      {hasStarted&&(
        <VideoCard title= {selectedVideo.title} embedCode={selectedVideo.embed} description={selectedVideo.description}/>
      )}
      {!hasStarted&&(
    <div className="flex items-center justify-center">
    <div className="flex flex-col items-center justify-center p-6 bg-white border border-gray-200 rounded-lg max-w-xl">
         <h2 className="text-xl font-semibold mb-2">Ready to Watch</h2>
         <p className="text-gray-700 mb-4">You are about to begin watching a video about Stairs and Buckets.</p>
         <button disabled={disabled} onClick={() =>beginVideo()} className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition-colors duration-300">
           Start Watching
         </button>
       </div>
       </div>
      )}
    </div>
    </>
    ):
    <>
    <div className="text-center pt-12">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Oops, looks like this link has expired...</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for. Please reach out to your agent if you would like them to send you this video again!</p>
        </div>
    </>}
    </>)}
    </> 
  );
};

export default ClientViewSection;
