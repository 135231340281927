import React, { useState } from 'react';

// A single collapsible item
const CollapsibleItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div className={`my-4 rounded-lg  ${isOpen?"bg-gray-100":""}`}>
      <button
        className="flex items-center w-full py-4 px-6 text-left"
        onClick={onClick}
      >
        <div
          className={`mr-3 transform transition-transform ${
            isOpen ? 'rotate-90' : ''
          }`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </div>
        <div className="flex-1 font-semibold text-lg">{question}</div>
      </button>
      {isOpen && (
        <div className="px-6 py-4 text-gray-500">
          {answer}
        </div>
      )}
    </div>
  );
};

// The entire FAQ list
const CollapsibleList = ({ faqs }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleItem = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="bg-white rounded-lg">
      {faqs.map((faq, index) => (
        <CollapsibleItem
          key={index}
          question={faq.question}
          answer={faq.answer}
          isOpen={openIndex === index}
          onClick={() => toggleItem(index)}
        />
      ))}
    </div>
  );
};

export default CollapsibleList;
