import { useState, useEffect } from "react"
import { useAuth } from "../../util/auth"
  import { useInvitationsByOwnerId, useInvitationsByOwnerEmail } from "../../util/db"
import LoadingIcon from "../LoadingIcon"
import ReceiptCard from "./ReceiptCard"
  export default function InboxSection({onValueChange}) {
    const auth = useAuth()

    const {
      data: receipts,
      status: receiptsStatus,
      error: receiptsError
    } = useInvitationsByOwnerId(auth.user.uid)

    const {
      data: receiptsUnsec,
      status: receiptsUnsecStatus,
      error: receiptsUnsecError
    } = useInvitationsByOwnerEmail(auth.user.email)



    useEffect(() => {
      if ((receipts && receiptsStatus === "success" && receiptsUnsec && receiptsUnsecStatus === "success") && (receiptsUnsec.length>0 || receipts.length>0)) {
        onValueChange(true)
      }

    }, [receipts, receiptsUnsec])
    return (
      <>
      {(receiptsStatus === "success" && receipts && receipts.length > 0)&&(
        <>
        {receipts.map((each, index) => {return <ReceiptCard key = {index} invitation = {each} uid = {auth.user.uid}/>
        })}
        </>
      )}
      {(receiptsUnsecStatus === "success" && receiptsUnsec && receiptsUnsec.length > 0)&&(
        <>
        {receiptsUnsec.map((each, index) => {return <ReceiptCard key = {index} invitation = {each} uid = {auth.user.uid}/>
        })}
        </>
      )}
      {((receiptsStatus === "success" && receipts.length === 0) && (receiptsUnsecStatus === "success" && receiptsUnsec.length === 0)) && <p>{"Looks like you have an empty inbox :)"}</p>}
      {(receiptsStatus === "loading" || receiptsUnsecStatus === "loading") && <LoadingIcon className = " w-6 "/>}
      </>

    )
  }
  