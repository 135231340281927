import React from "react";
import Meta from "../../components/Meta";
import { requireAuth } from "../../util/auth";
import TestimonialsSection from "../../components/TestimonialsSection";
import Section from "../../components/Section";
import CustomTestimonialsSection from "../../components/Custom/CustomTestimonialsSection";

function TestimonialsPage(props) {
  return (
    <div>
      <Meta title="Testimonials" />
      <CustomTestimonialsSection title="The Winter Circle" subtitle = "Hear what real customers have to say!"/>
    </div>
  );
}

export default TestimonialsPage;
