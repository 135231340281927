import { useLiveLinks } from "../../util/db"
import ValidatePaymentWrapper from "./ValidatePaymentWrapper"
import CopyTextComponent from "./CopyTextComponent"

  export default function WebinarCard(props) {
    const currentTime = new Date()
    const {
      data: webinars,
      error: webinarsError,
      status: webinarsStatus 
    }= useLiveLinks()

    function formatTime(seconds) {
        const date = new Date(seconds*1000)
        const weekArray = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        const monthArray = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "June", "July", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."]
        const dayOfWeek = date.getDay()
        const dayOfMonth = date.getDate()
        const month = date.getMonth()
        const time = [date.getHours() >12?date.getHours()-12:date.getHours(), date.getMinutes()].join(":")

        return `${weekArray[dayOfWeek]} ${monthArray[month]} ${dayOfMonth} - ${time} ${(parseInt(date.getHours())>=12)?"PM":"AM"}`
    }

    return (
        <div className="p-2 rounded-lg border-solid border border-green-600">
      <ul role="list" className="divide-y divide-gray-100">
        {(webinars&& webinarsStatus==="success"&&webinars.length>0)&&(<>
        {webinars.filter(element => element.finishTime.seconds > currentTime.getTime()/1000).map((webinar, index) => 
        {return index < 4&&          <li key={webinar.startTime.seconds} className="flex gap-x-4 py-5 justify-between">
        <div className="min-w-0">
          <p className="text-sm/6 font-semibold text-gray-900">{webinar.title}</p>
          <p className="mt-1 truncate text-xs/5 text-gray-500">{formatTime(webinar.startTime.seconds)}</p>
        </div>
      <ValidatePaymentWrapper visible={false}>
      <div className="text-center">

      {(webinar.startTime.seconds < Math.floor(currentTime.getTime()/1000) &&
           webinar.finishTime.seconds > Math.floor(currentTime.getTime()/1000)
          )?
          <a href={webinar.link}>
          <button className="px-4 py-1 text-white bg-green-600 rounded-lg text-center h-min">Watch Now</button>
          </a>:
          // <p className="pr-6 text-sm">Copy Link</p>
          <CopyTextComponent textToCopy={webinar.link}/>
        }
      </div>

      </ValidatePaymentWrapper>


        </li>}
        )}</>)}

        {(webinars && webinarsStatus==="success" && (webinars.length===0 || webinars.filter(element => element.finishTime.seconds>currentTime.getTime()/1000).length === 0))&& (
           <>
           <div className="flex min-w-0 gap-x-4 p-4 text-center align-center">
               <div className="flex flex-col items-center">
               <p className="text-md font-semibold leading-6 text-gray-900">
                   {"Looks like there aren't any scheduled upcoming webinars. Check back here later :)"}
               </p>
               </div>
           </div>
           </>
        )}
      </ul>
        </div>

    )
  }
  