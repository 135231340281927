import React from 'react';
import { useRouter } from '../../util/router';
import { useAuth } from '../../util/auth';
import LeadForm from './LeadForm';
import { Link } from '../../util/router';
import PDFView from './PDFView';

const TempLandingPageSection = () => {
  const auth = useAuth()
  const router = useRouter()
  const ebook = "https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/resources%2Febook.pdf?alt=media&token=c2d472b4-9204-4f0e-a819-fa481e68dde4"
  return (
    <>
    <div className='container'>
        {(router.query.status === "await")&&(
        <div className="container rounded-lg border border-green-700 p-4">
            <LeadForm zapTags = "user_on_react,ebook_lead" afterUrl = "/tmpl/success"/>
        </div> 
        )}
        {(router.query.status === "success")&&(
            <>
            <div className="min-h-screen bg-white flex flex-col items-center px-4">
            {/* Header Section */}
            <h1 className="text-4xl font-bold text-black mt-16 mb-6 text-center">
                Thank you for filling out the form
            </h1>
            <p className="text-lg text-gray-700 mb-12 text-center max-w-2xl">
                We appreciate your interest! Here's what you can do next to get the most out of our services. You can view the PDF below, we also sent you an email with it attached.
            </p>

            {/* PDF Embed Section */}
            <div className="w-full max-w-3xl mb-16">
                <PDFView pdf = {ebook}/>
            </div>


            {/* Next Steps Section */}
            <div className="w-full max-w-4xl mb-16">
                <h2 className="text-2xl font-semibold text-black mb-6">Next Steps</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="flex flex-col items-center text-center">
                    <div className="h-20 w-20 bg-green-700 text-white rounded-full flex items-center justify-center mb-4">
                    {/* Icon Placeholder */}
                    <span className="text-2xl font-bold">1</span>
                    </div>
                    <h3 className="text-xl font-semibold mb-2">Read the Guide</h3>
                    <p className="text-gray-600">
                    Check out the comprehensive guide in the PDF to understand all the features we offer.
                    </p>
                </div>
                <div className="flex flex-col items-center text-center">
                    <div className="h-20 w-20 bg-green-700 text-white rounded-full flex items-center justify-center mb-4">
                    {/* Icon Placeholder */}
                    <span className="text-2xl font-bold">2</span>
                    </div>
                    <h3 className="text-xl font-semibold mb-2">Create an Account</h3>
                    <p className="text-gray-600">
                    Sign in and then select a plan to start using Stairs & Buckets.
                    </p>
                </div>
                <div className="flex flex-col items-center text-center">
                    <div className="h-20 w-20 bg-green-700 text-white rounded-full flex items-center justify-center mb-4">
                    {/* Icon Placeholder */}
                    <span className="text-2xl font-bold">3</span>
                    </div>
                    <h3 className="text-xl font-semibold mb-2">Get Started</h3>
                    <p className="text-gray-600">
                    Begin your journey with us and enjoy the benefits of our premium services.
                    </p>
                </div>
                </div>
            </div>

            {/* Additional Information Section */}
            {/* <div className="w-full max-w-4xl mb-16">
                <h2 className="text-2xl font-semibold text-black mb-6">Why Choose Our Product</h2>
                <p className="text-lg text-gray-700 mb-8 text-center">
                Our product offers a unique combination of features designed to help you succeed.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="flex flex-col items-start">
                    <h3 className="text-xl font-semibold mb-2">Feature One</h3>
                    <p className="text-gray-600 mb-4">
                    Detailed description of feature one. It helps you achieve specific goals efficiently.
                    </p>
                </div>
                <div className="flex flex-col items-start">
                    <h3 className="text-xl font-semibold mb-2">Feature Two</h3>
                    <p className="text-gray-600 mb-4">
                    Explanation of feature two. It offers unparalleled benefits that set us apart.
                    </p>
                </div>
                <div className="flex flex-col items-start">
                    <h3 className="text-xl font-semibold mb-2">Feature Three</h3>
                    <p className="text-gray-600 mb-4">
                    Insights into feature three. Designed to streamline your workflow and save time.
                    </p>
                </div>
                <div className="flex flex-col items-start">
                    <h3 className="text-xl font-semibold mb-2">Feature Four</h3>
                    <p className="text-gray-600 mb-4">
                    Overview of feature four. It provides you with the tools needed for success.
                    </p>
                </div>
                </div>
            </div> */}

            {/* Call-to-Action Button */}
            <Link to = {auth.user?"/pricing/plans":"/auth/signup"}>
            <button className="bg-green-700 hover:bg-green-800 text-white font-semibold py-3 px-8 rounded-md shadow-md mb-16">
                Create An Account
            </button>
            </Link>

            </div>
            </>
        )}
    </div>
    </>
  );
};

export default TempLandingPageSection;
