import React, { useEffect, useState, useRef } from 'react';
import { getGPTThread, createGPTMessage, deleteGPTThread } from '../../util/useGPT';
import { deleteSupportThread } from '../../util/db';
import LoadingIcon from '../LoadingIcon';
import MarkdownRenderer from './MarkDownRenderer';

const ChatMessageView = ( {threadId, sender} ) => {
  const [newMessage, setNewMessage] = useState('');
  const defaultAvatar = 'https://via.placeholder.com/150?text=Avatar';
    const [messages, setMessages] = useState([])
    const [shouldListen, setShouldListen] = useState(false)

    const containerRef = useRef(null);

  async function getMessages(message) {
    try {
      const res = await getGPTThread(message);
      if (res.myThread.data.length- messages.length >0) {
        const filteredMessages = res.myThread.data
            .reverse()
            .filter((message) => message.content.length > 0);

        setMessages(filteredMessages)
        if (res.myThread.data[0].role === "assistant" && res.myThread.data[0].content.length===1) {
          setShouldListen(false)
        }
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      }
    } catch (error) {
      console.error('Error fetching chat completion:', error);
    }
  }


  async function deleteThread(id) {
    try {
      await deleteSupportThread(id.id)
      await deleteGPTThread(id.threadId)
    } catch (error) {
      console.log("Error deleting Thread:", error)
    }
  }

  const handleSendMessage = (e) => {
    e.preventDefault();

    if (!newMessage.trim()) return; // Prevent sending empty messages
    // Here you would normally send the message to your backend or chat service
    setShouldListen(true) 
    createGPTMessage(newMessage, threadId.threadId)
    setNewMessage('');
  };

  useEffect(() => {
    if (threadId.threadId) {
      getMessages(threadId.threadId)
    }
  }, [threadId.threadId])

  useEffect(() => {
    const timer = setInterval(() => {
      if (shouldListen) {
      getMessages(threadId.threadId)
      }
    }, 2000);

    return () => clearInterval(timer); // Cleanup on component unmount
  }, [shouldListen]);

  return (
    <div className="flex flex-col h-full w-full border rounded-lg border-gray-200 justify-between">
        <div className='flex flex-row justify-between bg-green-100 p-2 border border-gray-200 rounded-t rounded'>
            <div className='flex flex-col'>
                <div className='flex flex-row'>
                    <img
                    src={defaultAvatar}
                    className="h-12 w-12 rounded-full object-cover"
                />
                    <div>
                    <p className='font-bold text-lg pl-4'>Virtual Support</p>
                    <p className='text-sm pl-4 text-gray-400'>Case: {(threadId.id)?threadId.id[0]+threadId.id[1]+threadId.id[2]+threadId.id[3]+threadId.id[4]:"Loading"}</p>
                    </div>
                </div>
            </div>
            <button onClick={() => deleteThread(threadId)} className='bg-white p-2 h-fit border-red-500 border text-red-500 rounded-md'>Delete Chat</button>
        </div>
        {(messages && messages.length>0)&&
      <div ref={containerRef} className="flex-1 overflow-y-scroll p-4 max-h-96 space-y-4">
        {messages.map((message, index) => (
          <div key={index} className={`flex ${message.role === "user" ? 'justify-end' : 'justify-start'}`}>
            <div className='flex flex-col max-w-xl items-between'>
                <div className='flex flex-row justify-between'>
                <p className='font-semibold'>{(message.role==="user")?"You":sender}</p>
                </div>
                <div className={`p-3 rounded-lg ${message.role==="user" ? 'bg-green-100' : 'bg-gray-100 border'}`}>
                  <MarkdownRenderer markdown={message.content[0].text.value}/>
                </div>
            </div> 
          </div>
        ))}
        {shouldListen&&<LoadingIcon className="h-8"/>}
      </div>}
      <form
        className="border-t border-gray-200 p-4"
        onSubmit={handleSendMessage}
      >
        <div className='flex flex-row space-x-2'>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Send a message..."
          className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button disabled = {!(messages && messages.length > 0)} className="bg-green-700 text-white py-2 px-6 rounded-md hover:bg-green-600">
          Send
        </button>
        </div>
        
      </form>
    </div>
  );
};

export default ChatMessageView;
