import React, { useRef, useState } from 'react';
import { Worker, Viewer, SpecialZoomLevel, ViewMode } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import DisableRightClickWrapper from './DisableRightClickWrapper';
import { GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';

const PDFView = ({ pdf }) => {

    const [fullScreen, setFullScreen] = useState(false)
  GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;

  const toolbarPluginInstance = toolbarPlugin();

  const containerRef = useRef(null);


  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
        setFullScreen(true)
      if (containerRef.current) {
        if (containerRef.current.requestFullscreen) {
          containerRef.current.requestFullscreen();
        } else if (containerRef.current.mozRequestFullScreen) { /* Firefox */
          containerRef.current.mozRequestFullScreen();
        } else if (containerRef.current.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          containerRef.current.webkitRequestFullscreen();
        } else if (containerRef.current.msRequestFullscreen) { /* IE/Edge */
          containerRef.current.msRequestFullscreen();
        }
      }
    } else {
        setFullScreen(false)
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    }
  };

  return (
    <DisableRightClickWrapper>
      <div className="relative border border-gray-300 rounded-lg p-1 w-full md:w-3/4 mx-auto" ref={containerRef}>
      <button
          onClick={handleFullscreen}
          className=" absolute p-2 bg-green-500 text-white rounded z-10 hidden md:block left-4 top-4"
        >
          {!fullScreen?"Enter Fullscreen": "Exit Fullscreen"}
        </button>
        <div className="mb-4 w-full h-[50vh] md:h-screen">
          {pdf ? (
            <Worker workerUrl={`//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`}>
              <Viewer
                fileUrl={pdf}
                plugins={[toolbarPluginInstance]}
                defaultScale={SpecialZoomLevel.PageFit}
                viewMode={ViewMode.SinglePage}
              />
            </Worker>
          ) : (
            <p>There is no content here yet. Once the material is available it will show up here.</p>
          )}
        </div>
      </div>

    </DisableRightClickWrapper>
  );
};

export default PDFView;
