import React from "react";
import Meta from "../../components/Meta";
import { requireAuth } from "../../util/auth";
import AllBlogPostsSection from "../../components/Custom/AllBlogPostsSection";

function BlogPostsPage(props) {
  return (
    <>
      <Meta title="Blog" />
      <AllBlogPostsSection/>
    </>
  );
}

export default requireAuth(BlogPostsPage);
