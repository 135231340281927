import React, {useState} from 'react';
import ChatMessageView from './ChatMessageView';
import ChatListView from './ChatListView';
import { useAuth } from '../../util/auth';
import { useSupportThreads } from '../../util/db';

const CompleteChatView = () => {

  const [selected, setSelected] = useState(0)

  const auth = useAuth()

  const {data: threadIds,
    status: threadIdsStatus,
    error: threadIdsError
  } = useSupportThreads(auth.user.uid)

  const handleSelection = (data) => {
    setSelected(data)
  }



  return (
    <div className='flex flex-row space-x-2'>
        <div className='flex w-1/3'>
        <ChatListView selected = {selected} chats ={threadIds} uid = {auth.user.uid} handleSelection={handleSelection}/>
        </div>
        <div className="w-full">
          {(threadIds && threadIdsStatus === 'success' &&threadIds.length > 0)&&
        <ChatMessageView sender= "Help Center" threadId={(threadIds&&threadIds.length>0 && threadIdsStatus === "success")?threadIds[selected]:""}/>}
        </div>
    </div>
  );
};

export default CompleteChatView;
