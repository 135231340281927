import { useState } from "react"
  import { deleteInvitation, updateTeam, useTeam } from "../../util/db"
  export default function ReceiptCard({invitation, uid}) {
    const [buttonsDisabled, setButtonsDisabled] = useState(false)

    const {
      data: team,
      status: teamStatus,
      error: teamError
    } = useTeam(invitation.teamId)

    async function declineInvitation(id) {
      setButtonsDisabled(true)
      await deleteInvitation(id)
      setButtonsDisabled(false)
    }

    async function acceptInvitation(id, teamId) {
      setButtonsDisabled(true)
      var oldMembers = team.members
      if (!oldMembers.includes(uid)){
        oldMembers.push(uid)
      }
      await updateTeam( teamId, 
        {
            members: oldMembers   
        }
      )
      await deleteInvitation(id)
      setButtonsDisabled(false)
    }
    return (
      <>
      {(teamStatus === "success" && team)&&(
        <div className="bg-green-50 p-4 rounded-lg border-solid border border-green-600 mb-2">
          <div className="min-w-0 flex-auto space-y-2">
            <p className="text-md font-semibold leading-6 text-gray-900">{`Team Invitation:`}</p>
            <p className="mt-1 leading-5 text-gray-500 text-sm"><strong>{invitation.fromEmail}</strong> has invited you to join the team: <strong>{invitation.teamName}</strong></p>
            <div className="flex flex-row justify-around">
              <button disabled={buttonsDisabled} onClick={() => acceptInvitation(invitation.id, invitation.teamId)} className="bg-green-700 text-white px-2 py-1 rounded-lg">Accept Invitation</button>
              <button disabled={buttonsDisabled} onClick={() => declineInvitation(invitation.id)} className="bg-red-500 text-white  px-2 py-1 rounded-lg">Decline Invitation</button>
            </div>
          </div>
        </div>
      )}</>
        

    )
  }
  