import React from 'react';
import image from "../../assets/stairsandbucketswide.png"
import SendButtons from './SendButtons';
import ThreeCardRow from './ThreeCardRow';
import SmallCard from './SmallCard';

const SendableCourseCard = ( props ) => {
  return (
    <div className=" rounded overflow-hidden">
      <img className="w-full" src={image} alt="Card Image" />
      <div className="px-6 py-4">
        <div className="font-bold text-5xl mb-2">{props.title}</div>
        <SendButtons/>
        <div className="font-semibold text-2xl mb-2">Description</div>
        {props.paragraphs.map((each, key) => {
            return <div key = {key}>
                    <p className="text-gray-700 text-base">
                    {each}
                    <br/>
                    <br/>
                    </p>
            </div>
        })}
        <div className="font-semibold text-2xl mb-2">Video Usage</div>
        <ThreeCardRow>
            <SmallCard title="In the Office" description = "Select 'Office View' to show the video to clients in person."/>
            <SmallCard title="Virtual" description = "After sending to clients, you can track how many videos have been watched!"/>
            <SmallCard title="Track Progress" description = "Analytics for you and your team."/>
            <SmallCard title="Overview" description = "Stairs & Buckets helps you sell life insurance premium."/>
        </ThreeCardRow>

      </div>
    </div>
  );
};

export default SendableCourseCard;