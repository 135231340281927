import React from 'react';
import { Link } from '../../util/router';

const ResourceCard = ({ title, description, href, cta, photo }) => {
  return (
    <Link to ={href?href:"#"}>

    <div className="max-w-sm rounded overflow-hidden">
      {photo&&<img className="w-full rounded-3xl border border-green-700" src={photo} alt="Awaiting Card Image" />}
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{title}</div>
        <p className="text-gray-700 text-base">
          {description}
        </p>
      </div>
      <div className="px-6 pb-2">
          <button className=" bg-green-600 rounded-lg text-white p-2">{(cta?cta:"Learn More")}</button>
      </div>
    </div>
    </Link>
  );
};

export default ResourceCard;