import React, { useEffect, useState } from "react";
import { useRouter } from "../../util/router";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/24/solid";
import { Link } from "../../util/router";
import PDFView from "./PDFView";
import { useAllPDFs } from "../../util/db";
import ResourceCard from "./ResourceCard";
import PDFViewPPT from "./PDFViewPPT";
import ValidatePaymentWrapper from "./ValidatePaymentWrapper";

function PDFViewSection(props) {
  const [isShowing, setIsShowing] = useState(false);
  const [current, setCurrent] = useState({});
  
  const { data: embeds, status: embedsStatus, error: embedsError } = useAllPDFs();
  const router = useRouter();

  useEffect(() => {
    if (embeds && embedsStatus === "success") {
      const found = embeds.find((each) => each.id === router.query.id);
      if (found) {
        setCurrent(found);
        setIsShowing(true);
      } else {
        setCurrent({});
        setIsShowing(false);
      }
    }
  }, [embeds, embedsStatus, router.query.id]);

  return (
    <>
      {isShowing ? (
        <>
          <div className="pl-4 flex flex-row items-center pb-4">
            <Link to="/dashboard/dashboard">
              <HomeIcon className="text-gray-400 h-6" /> 
            </Link>
            <ChevronRightIcon className="px-4 text-gray-400 h-6" /> 
            <Link to="/tools/trainings">
              <p className="text-sm text-gray-400">All Resources</p>
            </Link>
            <ChevronRightIcon className="px-4 text-gray-400 h-6" /> 
            <div>
              <p className="text-lg font-semibold">{current.title}</p>
            </div>
          </div>
          <ValidatePaymentWrapper requiredPlans={current.requiredPlans}>
          {current.style==="ppt"?<PDFViewPPT pdf = {current.embed}/>:<PDFView pdf={current.embed} />}
          </ValidatePaymentWrapper>
        </>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {embedsStatus === "success" && embeds && embeds.length > 0 && 
            embeds.map((each, i) => (
              <ValidatePaymentWrapper key={i} requiredPlans={each.requiredPlans} small={true}>
              <ResourceCard  title={each.title} description={each.description} photo={each.coverPhoto} href={`?id=${each.id}`} />
              </ValidatePaymentWrapper>
            ))}

              <ValidatePaymentWrapper requiredPlans={["sby", "sbm","sbm1", "proc","procm", "tp","tpm","tpl", "life"]} small={true}>
                <ResourceCard title="Stairs & Buckets Eight Step Process" description="Use this tool to master the use of Stairs and Buckets." photo={""} href={`?id=8step`} />
              </ValidatePaymentWrapper>
        </div>
      )}
    </>
  );
}

export default PDFViewSection;
