import React from "react";
import { useAllBlogPosts } from "../../util/db";
import { useRouter } from "../../util/router";
import ResourceCard from "./ResourceCard";
import { Link } from "../../util/router";
function AllBlogPostsSection() {
    const {
        data: blogData,
        status: blogDataStatus,
        error: blogDataError
    } = useAllBlogPosts()
    const router = useRouter()

  return (
    <>
    {(blogData && blogData.length>0 && blogDataStatus==="success") &&(
        <>
        {(router.query.index === "all")&&(
        <div className="container mx-auto py-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {blogData.map((card, index) => (
                <div className="border border-2 rounded-3xl p-4">
                <ResourceCard key = {index} title={card.title} description={card.paragraphs[0]} href={`/blog/${index}`}/>
                </div>
            ))}
            </div>
        </div>
        )}
        
        {(parseInt(router.query.index) >=0)&&(
            <div className="items-center text-center">
            <div className="max-w-4xl mx-auto bg-white rounded-lg border border-gray-300 overflow-scroll text-start">
            <div className="p-6">
                <h2 className="text-green-700 text-4xl font-semibold">{blogData[parseInt(router.query.index)]?.title}</h2>
            </div>
            <div className="p-6 space-y-4">
                {blogData[parseInt(router.query.index)]?.paragraphs.map((paragraph, index) => (
                <p key={index} className="text-gray-800 text-xl">
                    {paragraph}
                </p>
                ))}
            </div>
            </div>
            <Link to ={"/blog/all"}>
            <button className="w-1/2 bg-green-700 p-4 rounded-lg m-6 text-white text-xl">Back to All Blog Posts</button>
            </Link>
            </div>
        )}
        </>
        )}
    </>
  );
}

export default AllBlogPostsSection;
