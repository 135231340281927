import React from 'react';

const InfoSection = (props) => {
  return (
    <>

    <div className={`py-12`}>
    {(props.header)&&<h1 className='text-center text-5xl font-semibold text-green-700'>{props.header}</h1>}
    {(props.subtitle)&&<h3 className='text-center text-2xl font-thin text-gray-500'>{props.subtitle}</h3>}
    <div className='border-b my-4 mx-32 border-green-700'></div>
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between px-4">
        {/* Image Section */}
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <img 
            src={props.photo?props.photo:"https://via.placeholder.com/600x400"}
            alt="Descriptive Alt Text" 
            className="rounded-lg border border-green-700"
          />
        </div>

        {/* Text Section */}
        <div className="lg:w-1/2 lg:pl-12">
          <h2 className="text-3xl font-semibold mb-4 text-green-800">{props.title}</h2>
            {props.perks?.map((each,k) =><p key = {k} className="text-lg mb-6 text-gray-800">
                {each}
            </p>
)}
          <a href={props.href?props.href:"#"} className="inline-block bg-green-600 cursor-pointer hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-md transition duration-300">
            {props.cta?props.cta: "Learn More"}
          </a>
        </div>
      </div>
    </div>
    </>
  );
};

export default InfoSection;
