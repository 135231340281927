import React from "react";
import Meta from "../../components/Meta";
import { requireAuth } from "../../util/auth";
import TeamsSection from "../../components/Custom/TeamsSection";

function TeamsPage(props) {
  return (
    <>
      <Meta title="Teams" />
      <TeamsSection/>
    </>
  );
}

export default requireAuth(TeamsPage);
