import React from "react";
import { useRouter } from "../../util/router";
import { useAuth } from "../../util/auth";
import SideNav from "./SideNav";
import SendableCourseCard from "./SendableCourseCard";
import ToggleButton from "./ToggleButton";
import VideoCard from "./VideoCard";
import { RadioGroup } from '@headlessui/react'
import { useState } from 'react'
import { useAllPDFs, useAllVideos, useLiveWebinarsByURLSuffix, useVideoByIndex } from "../../util/db";
import ResourceCard from "./ResourceCard";
import PDFViewSection from "./PDFViewSection";
import PDFView from "./PDFView";
import ValidatePaymentWrapper from "./ValidatePaymentWrapper";
import TitleCard from "./TitleCard";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/24/solid";
import { Link } from "../../util/router";
import PDFViewPPT from "./PDFViewPPT";
import Button from "../Button";



function SalesProcessSection(props) {
  const auth = useAuth();
  const router = useRouter()
  const memoryOptions = [
  {id: "sb", name: "Stairs & Buckets", green: ["sb", "ip"]},
  {id: "proc1", name: "Medicare", green: ["proc1"]},
  {id: "proc2", name: "Premium Life", green: ["proc2"]},
  {id: "proc3", name: "Finding the Money", green: ["proc3"]},
  {id: "proc4", name: "Protect & Participate", green: ["proc4"]},
  {id: "proc5", name: "Understanding Money", green: ["proc5"]},
  {id: "proc6", name: "Liability Wall", green: ["proc6"]},
  {id: "shuff", name: "Service to Sales", green: ["shuff"]},
  {id: "sp", name: "All Webinars", green: ["sp"]},
  {id: "trainings", name: "All Resources", green: ["trainings"]},
  {id: "sp-guests", name: "Special Guests", green: ["sp-guests"]},
  {id: "sp-topics", name: "Special Topics", green: ["sp-topics"]},
  ]
  const [mem, setMem] = useState(memoryOptions[0])
  const [selectedLanguage, setSelectedLanguage] = useState("English")

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const {
    data: allVideos,
    status: allVideosStatus,
    error: allVideosError
  } = useAllVideos()

  const {
    data: allEmbeds,
    status: allEmbedsStatus,
    error: allEmbedsError
  } = useAllPDFs()

  const {
    data: sb,
    status: sbStatus,
    error: sbError
  } = useVideoByIndex(0)

  const {
    data: webinars,
    status: webinarsStatus,
    error: webinarsError
  } = useLiveWebinarsByURLSuffix(router.query.section)
  

  return (
    <>

      <div className="container">
{/* For Toggling between sales processes and stairs */}
    <div className="flex flex-row items-center justify-between mx-4 sm:w-auto w-full">
  <fieldset aria-label="Choose an option">
      <RadioGroup value={mem} onChange={router.push} className="mt-2 grid grid-cols-3 gap-3 sm:grid-cols-6 cursor-pointer">
        {memoryOptions.map((option) => (
          <RadioGroup.Option
            key={option.id}
            value={"/tools/"+option.id}
            className={({ checked }) =>
              classNames(
                option.green.includes(router.query.section)
                  ? 'bg-green-600 text-white hover:bg-green-500'
                  : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
                'flex items-center justify-center rounded-md px-3 py-3 text-sm font-semibold sm:flex-1',
              )
            }
          >
            {option.name}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </fieldset>
    </div>


          <div className="flex flex-row justify-between items-center mb-6">
        {(router.query.section === "sb" || router.query.section === "ip")&&<ToggleButton section = {router.query.section} urlPrefix = "/tools" sectionsArray = {[{section: "sb", label: "Office View"},{section: "ip", label: "Customer View"}]}/>}
        </div>
      <div className="flex lg:flex-row flex-col">
        {router.query.section === "sb"&&(
          <>
          <div className="flex-grow-1 w-full lg:w-3/4 px-4">
          <ValidatePaymentWrapper requiredPlans={["sbm","sbm1", "sby", "tp","tpm","tpl", "proc","procm", "life"]}>
            <SendableCourseCard title = "Stairs and Buckets" paragraphs={["Stairs Method: The Stairs Method in life insurance is a strategy that involves structuring your coverage in tiers or 'steps' to match your anticipated financial needs over time. This method works by purchasing multiple policies that gradually decrease in coverage as your financial obligations lessen, such as paying off a mortgage, funding children's education, or nearing retirement. As time passes, the policies 'step down,' meaning certain policies expire or are no longer necessary. The advantage of this approach is that it provides higher coverage when you need it the most—typically when financial responsibilities are higher—and reduces costs as you move through life stages where less insurance may be required.","Bucket Method: The Buckets Method takes a more segmented approach, dividing your life insurance coverage into distinct 'buckets,' each designed to cover specific financial needs or goals. Each bucket targets a particular obligation, such as one for immediate needs like funeral expenses, another for long-term goals like replacing income for dependents, and a third for potential medical or long-term care expenses. This method provides a clear, focused plan for ensuring that all major financial responsibilities are covered. The buckets can be funded through different types of policies or even a combination of life insurance products, providing flexibility to adapt to different life circumstances."]}/>
          </ValidatePaymentWrapper>
          </div>
          <div className="flex flex-col">
          {(webinarsStatus==="success" &&webinars && webinars.length>0)&&
            <div className="px-4">
                  <TitleCard title = "Related Webinars">
                    {webinars.map((each, i) => 
                    <div key={i} className="w-full border-t border-gray-400">
                    <ValidatePaymentWrapper requiredPlans={each.requiredPlans} small={true}>

                        <div className="flex flex-col">
                          <p className="text-green-700 font-semibold text-xl">{each.title}</p>
                          <p className="text-gray-500 text-sm">{each.subtitle}</p>
                        </div>
                        <Link to = {`/view/member/${each.id}`}>
                        <button className=" m-2 bg-green-600 text-white hover:bg-green-500 flex items-center justify-center rounded-md w-full py-3 text-sm font-semibold sm:flex-1">View Now</button>
                        </Link>
                      </ValidatePaymentWrapper>
                      </div>
                    )}
                  </TitleCard>
            </div>
          }
          <div className="flex px-4 justify-center">
            {allVideosStatus === "success"&& allVideos&&
            <TitleCard title = "Using this Tool">
            <ValidatePaymentWrapper requiredPlans={["sby", "sbm","sbm1", "tp","tpm","tpl", "proc","procm", "life"]} small={true}>
              <ResourceCard photo={allVideos[0].coverPhoto} cta = "Watch Now" title={`1. ${allVideos[0].title}`} description={allVideos[0].description} href = {`/view/member/${allVideos[0].id}`}/>
            </ValidatePaymentWrapper>
            <ValidatePaymentWrapper requiredPlans={["sby", "sbm","sbm1", "tp","tpm","tpl", "proc","procm", "life"]} small={true}>
              <ResourceCard photo={""} cta = "Read Now" title={`8-Step Guide`} description={"Read this guide to master Stairs & Buckets."} href = {`/tools/trainings/?id=8step`}/>
            </ValidatePaymentWrapper>
            </TitleCard>}
          </div>
          </div>
          </>
        )}
        {(router.query.section === "ip" && sbStatus==="success" && sb.length>0)&&(<>
        <div className="w-full">
        <span className="isolate lg:inline-flex rounded-md mb-4">
                <button
                    onClick={()=>{setSelectedLanguage("English")}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${selectedLanguage==="English"?"bg-green-100 text-green-700 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    English
                </button>
                <button
                    onClick={()=>{setSelectedLanguage("Spanish")}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${selectedLanguage==="Spanish"?"bg-green-100 text-green-700 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Spanish
                </button>
                <button
                    onClick={()=>{setSelectedLanguage("Mandarin")}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${selectedLanguage==="Mandarin"?"bg-green-100 text-green-700 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Mandarin
                </button>
                <button
                    onClick={()=>{setSelectedLanguage("Cantonese")}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${selectedLanguage==="Cantonese"?"bg-green-100 text-green-700 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Cantonese
                </button>
            </span>
        <ValidatePaymentWrapper requiredPlans={["sby", "sbm","sbm1", "proc","procm", "tp","tpm","tpl", "life"]}>
        <VideoCard title= {sb.filter(each => each.language === selectedLanguage)[0].title} embedCode={sb.filter(each => each.language === selectedLanguage)[0].embed} description={sb.filter(each => each.language === selectedLanguage)[0].description}/>
        </ValidatePaymentWrapper>
        </div>
        </>)}
          
        </div>

        {(router.query.section === "sp")&&(<>
        {(allVideosStatus === "success" && allVideos && allVideos.length > 0)&&(<>
          <div className="container mx-auto p-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {allVideos.map((each, i) => (
            // (i ===0)?<ValidatePaymentWrapper key = {i}  requiredPlans={["sby", "sbm", "proc", "shuff", "tp"]} small={true}>
            //   <ResourceCard photo={each.coverPhoto} cta = "Watch Now" title={`${i+1}. ${each.title}`} description={each.description} href = { i === 0?"/tools/ip":`/view/member/${each.id}`}/>
            // </ValidatePaymentWrapper>:
          <ValidatePaymentWrapper key = {i}  requiredPlans={each.requiredPlans} small={true}>
            <ResourceCard photo={each.coverPhoto} cta = "Watch Now" title={`${i+1}. ${each.title}`} description={each.description} href = {each.itemId?.includes("shuff")?`/tools/${each.itemId.slice(0,-1)}`:each.id === "JEqPyiheOSCbFWTMVknP"?`/view/member/${each.id}`:`/tools/${each.itemId}`}/>
          </ValidatePaymentWrapper>                                                                                                           
            
          ))}
          </div>
          </div>
        </>
        )}
        </>)}

        {(router.query.section === "trainings")&&(<>
        {router.query.id==="8step"?
        <>
        <div className="pl-4 flex flex-row items-center pb-4">
            <Link to="/dashboard/dashboard">
              <HomeIcon className="text-gray-400 h-6" /> 
            </Link>
            <ChevronRightIcon className="px-4 text-gray-400 h-6" /> 
            <Link to="/tools/trainings">
              <p className="text-sm text-gray-400">All Resources</p>
            </Link>
            <ChevronRightIcon className="px-4 text-gray-400 h-6" /> 
            <div>
              <p className="text-lg font-semibold">{"8-Step Guide"}</p>
            </div>
          </div>
        <div className="w-full border border-green-700 rounded-lg p-2">
          <div className="w-full bg-green-800 p-8">
            <p className="text-white text-4xl ml-4">
            8-STEP DISCUSSION GUIDE
            </p>
          <br></br>
            <p className="text-white text-md ml-4">
            What we have found is that the Stairs and Buckets video clip is like a baseball bat. You can step up to the base and swing all day long. Perhaps you’ll connect and get on base occasionally. But, if you have a bad swing, the bat doesn’t help you. What you need is a good swing to go along with a good bat. We have figured out how to swing the bat to get great results.
            </p>
          </div>
          <div className="w-full p-8">
            <p className="text-4xl ml-4">
            SO <strong>WELCOME</strong> TO THE INSURANCE TRAINING INSTITUTE
            </p>
            <br/>
            <p className="text-4xl ml-4 text-green-900 font-bold">
            HERE IS OUR SWING…
            </p>
            <br/>
            <p className="text-xl ml-4 font-bold">
            1 – RUN A LIST OF EVERY CUSTOMER THAT OWNS LIFE INSURANCE EVERY YEAR AND MEET WITH THEM
            </p>
          <br></br>
            <p className=" text-md ml-4">
            Why? Because they are already believers and are the ones most inclined to buy more life insurance. In fact, 82% of all life insurance in America is purchased by those who already own it.
            </p>
          </div>
          <div className="w-full bg-green-800 p-8">
          <p className="text-xl text-white ml-4 font-bold">
          2 – BEGIN BY CALLING AND SCHEDULING EVERY LIFE INSURANCE CUSTOMER FOR AN ANNUAL REVIEW.            </p>
          <br></br>
            <p className="text-white text-md ml-4">
            Your initial call must sound urgent. It should go something like this: “Mr. and Mrs. Customer – this is Kathy calling from Jerry’s office, Jerry asked me to give you a call and schedule a time you can meet with him because he is worried about your life insurance policy. Things are changing, and it is critical you come in and meet before your policy becomes a problem for you. Can you come in this week or next?"
            </p>
            <br/>
            <p className="text-white text-md ml-4">
            The person setting the appointment most likely will be asked what’s wrong; it is important that they tell the customer that they were asked to get you in as soon as possible because of a problem but they don’t know the details. Their job is to get the customer in front of you. This appointment should be set with a feeling of urgency.
            </p>
          </div>
          <div className="w-full p-8">
          <p className="text-xl ml-4 font-bold">
            3 – WHEN THEY COME IN:
            </p>
          <br></br>
            <p className=" text-md ml-4">
            Open your conversation when you greet them like this: “Thank you for coming in, I recognize you have a dozen other things you would probably rather be doing right now. Frankly, I do too, but this is important and when I heard you were coming in I cleared my schedule to meet with you. Because Death is so final, we have to get your life insurance right every single time.”
            </p>
            <br/>
            <p className=" text-md ml-4">
            Note that it is important the customer hears you say that you realize they are busy. But, it is also critical they understand how valuable your time is too because you are in demand.
            </p>
          </div>
          <div className="w-full bg-green-800 p-8">
          <p className="text-xl text-white ml-4 font-bold">
            4 – NEXT - YOU MUST SET THE AGENDA BEFORE YOU DO ANYTHING ELSE:
            </p>
          <br></br>
            <p className="text-white text-md ml-4">
            “Mr. or Mrs. Customer – before we get started – I want you to know we have three objectives for today’s meeting:
            </p>
            <br></br>
            <p className="text-white text-md ml-4">
            1 - Do nothing; I just want to show you your policy so you understand what the problem is you are facing and you don’t have to do anything. It just important that you know what you may be confronted with down the road.
            </p>
            <br></br>

            <p className="text-white text-md ml-4">
            2 - Show you how you can solve your problem so that it is taken care of and never happens again.
            </p>
            <br></br>

            <p className="text-white text-md ml-4">
            3 - If you can’t solve the problem today, I can show you how you can buy yourself some time by showing you how we can kick the can down the road and deal with it later.” Step two shows them how they can convert a short term or “stair” type policies into long term or “bucket” policies like Universal and Whole life policies. Step three helps them buy more term “stair” coverage for longer period of time so it last longer before terminating or increasing in cost.
            </p>
          </div>
          <div className="w-full p-8">
            <p className="text-xl font-bold ml-4">
            5 – BEFORE YOU ANSWER ANY QUESTIONS…
            </p>
          <br/>
            <p className=" text-md ml-4">
            At this point, the customer may be a little disturbed. Transition to this: “So before we look at your policy, it’s important to take a moment and overview life insurance in general and how it works. I have this short illustration I would like to go over with you that will answer a lot of questions and give you a basic understanding of how the many different policies work. It is about four and a half minutes long – let’s review this first…”
            </p>
            <br/>

            <p className="text-xl font-bold ml-4">
            6 – AFTER GOING THROUGH THE ILLUSTRATION TOGETHER, SHOW THEM THEIR “STAIR POLICY”
            </p>
            <br/>

            <p className=" text-md ml-4">
            Have the customer scoot up and turn the computer screen so he or she can see the screen. Show them their own policy. If it is a term, tell them they have a “stair” policy that is turning into a ticking time bomb. Let’s say it is a 20-year term with nine years left. I remind them that they bought a 20-year “stair” policy 11 years ago and that there is going to be a huge increase in nine more years; ultimately, it will implode if not dealt with.
            </p>
            <br/>

            <p className=" text-md ml-4">
            We re-propose the policy on the computer screen, showing them the large stair increases in nine years. Then, ask them if this is what they want to have happen to their policy. Most of the time the customer has no idea what they will be confronted with and they will want to solve this issue today.
            </p>
          </div>
          <div className="w-full bg-green-800 p-8">
            <p className="text-white text-xl font-bold ml-4">
            7 – PRESENT THE CUSTOMER WITH A CONVERSION OPTION INTO A “BUCKET”
            </p>
            <br/>
            <p className="text-white text-md ml-4">
            Tell them your second objective is to show them how they can convert their “stair” policy into a “bucket” policy that prevents increases and will help them save and accumulate cash for the future. Explain the conversion credits if they are available.
            </p>
            <br/>

            <p className="text-white text-md ml-4">
            If they have a “bucket" policy, talk to them about the need for more coverage than what they have. You can also ask why they have coverage like this on themselves, but not their spouse. “If it is good enough for you, why don’t you have one for your spouse?”
            </p>
            <br/>

            <p className="text-white text-md ml-4">
            If they have it on both parents, ask them, “If this is good enough for you, why haven’t you started something like this for your kids?”
            </p>
          </div>
          <div className="w-full p-8">
          <p className="text-xl font-bold ml-4">
          8 – DISCUSS TERM RESET OPTION WITH CURRENT “STAIRS” FOR TERM CUSTOMERS
            </p>
            <br/>
            <p className=" text-md ml-4">
            If your customers are still healthy, and are willing to submit to new underwriting, they can apply for as little as a $100k more than what they currently have. You can take a new application for increased coverage and/or increased term and the company will allow them to extend their term out to another 10, 20, or 30 years.
            </p>
            <br/>
            <p className=" text-md ml-4">
            Illustrate these numbers for them in your program and discuss the benefits and cost associate with the additional time they are buying. Explain that by taking a new application and submitting to underwriting they may or may not get the same or more favorable ratings.
            </p>
          </div>
          <div className="w-full bg-green-800 p-8">
            <p className="text-white text-xl font-bold ml-4">
            LET THE CUSTOMER GIVE HIS OR HER ANSWER
            </p>
            <br/>
            <p className="text-white text-md ml-4">
            Answer any remaining questions. Then, sit back and ask them “How would you like me to proceed? – Would you like to give this a try?” Let the customers decide what they want to do and what is most suitable for their needs. Then, take care of it right then.
            </p>
            <br/>

            <p className="text-white text-md ml-4">
            Make sure you let them know you will call them every year at this time to come in and review their life Insurance as it is so important that they get it right. Every year, review the difference between “stairs” and “buckets” and show them what they are currently doing and what other options are available. At the end, always ask them how they want to proceed.
            </p>
            <br/>

            <p className="text-white text-md ml-4">
            Bottom line is this is about them, let them determine what works best for them. And, because death is so final, let’s get this right every single time! 
            </p>
          </div>
        </div>
        </>:
          <div className="container mx-auto p-4">
            <PDFViewSection/>
          </div>}
        </>)}

        {(router.query.section === "sp-guests")&&(<>
          <div className="flex lg:flex-row flex-col">
          <div className={`flex-grow-1 w-full ${(webinarsStatus==="success" && webinars.length>1)?"lg:w-3/4":""} px-4`}>

        {(webinarsStatus === "success" && webinars && webinars.length > 0)&&(<>
          <div className="container mx-auto p-4">
              <ValidatePaymentWrapper  requiredPlans={webinars[0].requiredPlans}>
                <VideoCard title= {webinars[0].title} embedCode={webinars[0].embed} description={webinars[0].description}/>
              </ValidatePaymentWrapper>
          </div>

        </>
        )}
        </div>
        {(webinarsStatus === "success" && webinars && webinars.length>1)&&<div className="flex px-4 justify-center">
            <TitleCard title = "Other Webinars">
              {webinars.map((each, i) => 
              (i>0)&&<div key={i} className="w-full border-t border-gray-400">
              <ValidatePaymentWrapper requiredPlans={each.requiredPlans} small={true}>

                  <div className="flex flex-col">
                    <p className="text-green-700 font-semibold text-xl">{each.title}</p>
                    <p className="text-gray-500 text-sm">{each.subtitle}</p>
                  </div>
                  <Link to = {`/view/member/${each.id}`}>
                  <button className=" m-2 bg-green-600 text-white hover:bg-green-500 flex items-center justify-center rounded-md w-full py-3 text-sm font-semibold sm:flex-1">View Now</button>
                  </Link>
                </ValidatePaymentWrapper>
                </div>
              )}
            </TitleCard>
        </div>}
        </div>
        </>)}

        {(router.query.section === "sp-topics")&&(<>
        <>
          <div className="flex items-center justify-center">
            <div className="w-full max-w-sm p-8 bg-white rounded-lg border border-green-700 text-center">
              <h1 className="text-2xl font-semibold text-green-900 mb-4">Coming Soon</h1>
              <p className="text-green-700 mb-6">
                This section will be coming soon. Check back frequently to view special topics.
              </p>
            </div>
          </div>
        </>
        </>)}

        {/* NOT SOLD ON THIS IDEA */}
      <div className="flex lg:flex-row flex-col">
      <div className={`flex-grow-1 w-full ${(webinarsStatus==="success" && webinars.length>0)?"lg:w-3/4":""} px-4`}>
        {(allEmbedsStatus === "success" && allVideosStatus === "success")&&(<>
        {(router.query.section === "proc1")&&(<>
            <div className="container mx-auto p-4 space-y-2">
              <ValidatePaymentWrapper  requiredPlans={allVideos[1].requiredPlans} small={true}>
                <VideoCard title= {allVideos[1].subtitle} embedCode={allVideos[1].embed} description={allVideos[1].description} uid = {auth.user.uid} postId = {allVideos[1].id} prev={auth.user.finishedContent}/>
                <PDFViewPPT pdf={allEmbeds.find(each => each.id === "71RibWOPyXc1Z0oc6EC8").embed}/>
              </ValidatePaymentWrapper>
            </div>
        </>)}
        {(router.query.section === "proc2")&&(<>
          <div className="container mx-auto p-4 space-y-2">
            <ValidatePaymentWrapper  requiredPlans={allVideos[2].requiredPlans} small={true}>
            <VideoCard title= {allVideos[2].subtitle} embedCode={allVideos[2].embed} description={allVideos[2].description} uid = {auth.user.uid} postId = {allVideos[2].id} prev={auth.user.finishedContent}/>
            <PDFView pdf={allEmbeds.find(each => each.linkedResources?.includes(router.query.section)).embed}/>
            </ValidatePaymentWrapper>
          </div>
        </>)}
        {(router.query.section === "proc3")&&(<>
          <div className="container mx-auto p-4 space-y-2">
            <ValidatePaymentWrapper  requiredPlans={allVideos[3].requiredPlans} small={true}>
            <VideoCard title= {allVideos[3].subtitle} embedCode={allVideos[3].embed} description={allVideos[3].description} uid = {auth.user.uid} postId = {allVideos[3].id} prev={auth.user.finishedContent}/>
            <PDFView pdf={allEmbeds.find(each => each.linkedResources?.includes(router.query.section)).embed}/>
            </ValidatePaymentWrapper>
            <div className="flex flex-row justify-center w-full m-4 space-x-4">
            <a href="https://www.bankrate.com/mortgages/amortization-calculator/#:~:text=Amortization%20is%20paying%20off%20a,grows%20larger%20month%20by%20month.">
            <Button  size="xl" variant="primary">
            Bankrate Amortization Calculator
            </Button>
            </a>
            <a href="https://www.bankrate.com/banking/savings/compound-savings-calculator/">
            <Button  size="xl" variant="primary">
            Bankrate Compound Interest Calculator
            </Button>
            </a>
            </div>
          </div>
        </>)}
        {(router.query.section === "proc4")&&(<>
          <div className="container mx-auto p-4">
            <ValidatePaymentWrapper  requiredPlans={allVideos[4].requiredPlans} small={true}>
            <VideoCard title= {allVideos[4].subtitle} embedCode={allVideos[4].embed} description={allVideos[4].description} uid = {auth.user.uid} postId = {allVideos[4].id} prev={auth.user.finishedContent}/>
            <PDFView pdf={allEmbeds.find(each => each.linkedResources?.includes(router.query.section)).embed}/>
            </ValidatePaymentWrapper>
          </div>
        </>)}
        {(router.query.section === "proc5")&&(<>
          <div className="container mx-auto p-4 space-y-2">
            <ValidatePaymentWrapper  requiredPlans={allVideos[5].requiredPlans} small={true}>
            <VideoCard title= {allVideos[5].subtitle} embedCode={allVideos[5].embed} description={allVideos[5].description} uid = {auth.user.uid} postId = {allVideos[5].id} prev={auth.user.finishedContent}/>
            <PDFView pdf={allEmbeds.find(each => each.linkedResources?.includes(router.query.section)).embed}/>
            </ValidatePaymentWrapper>
          </div>
        </>)}
        {(router.query.section === "proc6")&&(<>
          <div className="container mx-auto p-4 space-y-2">
            <ValidatePaymentWrapper  requiredPlans={allVideos[6].requiredPlans} small={true}>
            <VideoCard title= {allVideos[6].subtitle} embedCode={allVideos[6].embed} description={allVideos[6].description} uid = {auth.user.uid} postId = {allVideos[6].id} prev={auth.user.finishedContent}/>
            <PDFView pdf={allEmbeds.find(each => each.linkedResources?.includes(router.query.section)).embed}/>

            <p className="text-xl">Click <strong className="text-green-700"><a href="https://acc.insuranceagencytraining.org/wp-content/uploads/2024/03/LIability-Wall-Calculatorv2.xlsx
">Here</a></strong> Here to download the calculator.</p>
            <p className="text-xl">Click <strong className="text-green-700"><a href="https://acc.insuranceagencytraining.org/wp-content/uploads/2024/03/LIability-Wall-Template.pdf
">Here</a></strong> Here to download the template.</p>

            </ValidatePaymentWrapper>
          </div>
        </>)}
        {(router.query.section === "shuff")&&(<>
          <div className="container mx-auto p-4">
            <ValidatePaymentWrapper  requiredPlans={allVideos[7].requiredPlans}>
            <VideoCard title= {allVideos[7].subtitle} embedCode={allVideos[7].embed} description={allVideos[7].description} uid = {auth.user.uid} postId = {allVideos[7].id} prev={auth.user.finishedContent}/>
            </ValidatePaymentWrapper>
            <ValidatePaymentWrapper  requiredPlans={allVideos[8].requiredPlans}>
            <VideoCard title= {allVideos[8].subtitle} embedCode={allVideos[8].embed} description={allVideos[8].description} uid = {auth.user.uid} postId = {allVideos[8].id} prev={auth.user.finishedContent}/>
            </ValidatePaymentWrapper>
            <ValidatePaymentWrapper  requiredPlans={allVideos[9].requiredPlans}>
            <VideoCard title= {allVideos[9].subtitle} embedCode={allVideos[9].embed} description={allVideos[9].description} uid = {auth.user.uid} postId = {allVideos[9].id} prev={auth.user.finishedContent}/>
            <PDFView pdf={allEmbeds.find(each => each.linkedResources?.includes(router.query.section)).embed}/>
            </ValidatePaymentWrapper>
          </div>
        </>)}
          </>)}

      </div>
      {(webinarsStatus==="success" && webinars.length>0 && !["sp-guests", "sb"].includes(router.query.section))&&
      <div className="flex px-4 justify-center">
            <TitleCard title = "Related Webinars">
              {webinars.map((each, i) => 
              <div key={i} className="w-full border-t border-gray-400">
              <ValidatePaymentWrapper requiredPlans={each.requiredPlans} small={true}>

                  <div className="flex flex-col">
                    <p className="text-green-700 font-semibold text-xl">{each.title}</p>
                    <p className="text-gray-500 text-sm">{each.subtitle}</p>
                  </div>
                  <Link to = {`/view/member/${each.id}`}>
                  <button className=" m-2 bg-green-600 text-white hover:bg-green-500 flex items-center justify-center rounded-md w-full py-3 text-sm font-semibold sm:flex-1">View Now</button>
                  </Link>
                </ValidatePaymentWrapper>
                </div>
              )}
            </TitleCard>
      </div>}
      </div>
      </div>
      <SideNav current = "tools"></SideNav>
    </>

  );
}

export default SalesProcessSection;


