import React from "react";
import Meta from "../../components/Meta";
import { requireAuth } from "../../util/auth";
import HelpSection from "../../components/Custom/HelpSection";

function HelpPage(props) {
  return (
    <>
      <Meta title="Help" />
      <HelpSection/>
    </>
  );
}

export default requireAuth(HelpPage);
