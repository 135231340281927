import React from "react";
function CSVExporter(props) {

  function convertToCSV(jsonData) {
    if (!jsonData || !jsonData.length) {
      return "";
    }
  
    const headers = Object.keys(jsonData[0]);
    const csvRows = [];
  
    // Add the header row, quoted
    csvRows.push(headers.map(header => `"${header}"`).join(","));
  
    for (const row of jsonData) {
      const values = headers.map(header => {
        let val = row[header] === null || row[header] === undefined ? "" : row[header].toString();
  
        // Escape quotes by doubling them
        val = val.replace(/"/g, '""');
  
        // Wrap each field in quotes to handle commas and newlines
        return `"${val}"`;
      });
  
      csvRows.push(values.join(","));
    }
  
    return csvRows.join("\n");
  }

  const downloadCSV = (csvString, filename = "data.csv") => {
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    a.style.display = 'none'; 
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  };

  const handleExport = () => {
    const csvString = convertToCSV(props.data);
    downloadCSV(csvString, "my_data.csv");
  };

  return (
    <div>
      <button className='border border-green-700 text-green-700 m-2 px-2 rounded-md' onClick={handleExport}>Export Selected Users</button>
    </div>
  );
}

export default CSVExporter;
