import React from "react";
import Meta from "./../components/Meta";
import SettingsSection from "./../components/SettingsSection";
import { requireAuth } from "./../util/auth";
import CustomSettingsSection from "../components/Custom/CustomSettingsSection";

function SettingsPage(props) {

  return (
    <>
      <Meta title="Settings" />
      <CustomSettingsSection/>
      {/* <SettingsSection
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        section={router.query.section}
        key={router.query.section}
      /> */}
    </>
  );
}

export default requireAuth(SettingsPage);
