import React, { useEffect } from 'react';

const DisableRightClickWrapper = ({ children }) => {
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  return <div>{children}</div>;
};

export default DisableRightClickWrapper;
