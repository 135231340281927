import React from "react"
import { useState } from "react"
import {PlusCircleIcon, CakeIcon} from "@heroicons/react/24/solid";
import { createLiveLink } from "../../util/db";
import { Timestamp } from "firebase/firestore";

export default function HostZoom() {
    const currentDate = new Date();
    const [isOpen, setisOpen] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [link, setLink] = useState("")
    const [time, setTime] = useState("")
    const [start, setStart] = useState("")
    const [date, setDate] = useState("")
    const [title, setTitle] = useState("")
    const [rest, setRest] = useState([])
    const [successfullPost, setSuccessfullPost] = useState(false)

    function getTimestampFromTimeString(timeString) {
        if(!date || !date.match(/^\d{4}-\d{2}-\d{2}$/)) {
          console.error("Invalid date format");
          return;
        }
        
        const selectedDate = new Date(); // fresh Date object
        const [year, month, day] = date.split('-').map(Number);
      
        selectedDate.setFullYear(year, month - 1, day);
      
        const [hours, minutes] = timeString.split(':').map(Number);
        selectedDate.setHours(hours);
        selectedDate.setMinutes(minutes);
        selectedDate.setSeconds(0);
        selectedDate.setMilliseconds(0);
      
        return Timestamp.fromDate(selectedDate);
      }
      
    function modifyArray(mainArray, modArray) {
        // Create a new array to avoid mutating the original mainArray
        const resultArray = [...mainArray];
      
        modArray.forEach((item) => {
          if (resultArray.includes(item)) {
            // If item is already in mainArray, remove it
            const index = resultArray.indexOf(item);
            resultArray.splice(index, 1);
          } else {
            // If item is not in mainArray, add it
            resultArray.push(item);
          }
        });
      
        return resultArray;
      }

    function closePopup() {
        setisOpen(false)
        setSuccessfullPost(false)
        setButtonDisabled(false)
      }
    async function updateDatabase() {
        setButtonDisabled(true)
        await createLiveLink({
            title: title,
            link: link,
            startTime: getTimestampFromTimeString(start),
            requiredPlans: rest,
            finishTime: getTimestampFromTimeString(time),
        })
        setSuccessfullPost(true)
        setButtonDisabled(false)
    }
    return (
        <>
        {(isOpen)&&(
                <div className="z-20 fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
                  {/* Popup content */}
                  <div className="bg-white p-8 rounded-lg shadow-lg lg:w-1/2 w-3/4 max-w-4xl relative">
                    {successfullPost===true?(
                    <div className="flex flex-col text-center space-y-4">
                    <CakeIcon className='h-12 text-green-700'/>
                    <h2 className='text-5xl font-semibold'>Users can Now Access The Zoom call From Their Dashboard!</h2>
                    <p className='text-lg font-semithin'>Woohoo! The users will be able to view the notification that we will be live from their dashboard during the selected time.</p>
                    <button onClick={()=> closePopup()} className=" absolute top-0 right-4 text-3xl text-gray-400 rounded-md">
                    &times;
                  </button>
                  </div>
                  ):(
                    <>
                    <h2 className=" text-center text-5xl font-bold pb-6">Schedule Livestream</h2>
                    <p className='text-gray-600 text-center'>Please enter the relevant information before going live.</p>
                    <button onClick={()=> closePopup()} className=" absolute top-0 right-4 text-3xl text-gray-400 rounded-md">
                      &times;
                    </button>

                    <div className='h-0 my-4 border-t-4 border-dotted border-gray-200'></div>
                    <p className='text-gray-400 text-left m-2'>Title</p>
                    <div className='mb-2'>
                        <label htmlFor="email" className="sr-only">
                            Title
                        </label>
                        <input
                            type="text"
                            name="link"
                            id="link"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder='The Public Facing Title'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <p className='text-gray-400 text-left m-2'>Zoom Link</p>
                    <div className='mb-2'>
                        <label htmlFor="email" className="sr-only">
                            Zoom Link
                        </label>
                        <input
                            type="text"
                            name="link"
                            id="link"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder='https://us.zoom.us/1234'
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                        />
                    </div>
                    <p className='text-gray-400 text-left m-2'>{`Date`}</p>
                    <div className='mb-2'>
                        <input
                            type="date"
                            name="date"
                            id="date"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder='https://us.zoom.us/1234'
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-row justify-around">
                    <div>                        
                    <p className='text-gray-400 text-left m-2'>Start Time</p>
                    <div className='mb-2'>
                        <input
                            type="time"
                            name="time-start"
                            id="time-start"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder='https://us.zoom.us/1234'
                            value={start}
                            onChange={(e) => setStart(e.target.value)}
                        />
                    </div>
                    </div>
                    <div>
                    <p className='text-gray-400 text-left m-2'>Finish Time</p>
                    <div className='mb-2'>
                        <label htmlFor="email" className="sr-only">
                            Finish Time
                        </label>
                        <input
                            type="time"
                            name="time"
                            id="time"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder='https://us.zoom.us/1234'
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                        />
                    </div>
                    </div>
                    </div>
                    <p className='text-green-700 text-center text-sm'>Please note the above times are in 24-hour time.</p>
                    <p className='text-gray-400 text-left m-2'>Select a Permission Level</p>
            <span className="isolate lg:inline-flex rounded-md mb-2">
                <button
                    onClick={()=>{setRest(modifyArray(rest, ['any']))}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${rest.includes("any")?"bg-green-100 text-green-700 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Anyone
                </button>
                <button
                    onClick={()=>{setRest(modifyArray(rest, ["sby", "sbm","sbm1"]))}}
                    // onClick={()=>{setRest(["sby", "sbm","sbm1","tp", "tpl","tpm","procm", "proc", "life"])}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${rest.includes("sbm")?"bg-green-100 text-green-700 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Million Dollar Stairs and Buckets Start-Up
                </button>
                <button
                    onClick={()=>{setRest(modifyArray(rest, ["procm", "proc"]))}}
                    // onClick={()=>{setRest(["tp","tpm","tpl", "proc", "procm", "life"])}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${rest.includes("proc")?"bg-green-100 text-green-700 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Million Dollar Tool Kit
                </button>
                <button
                    onClick={()=>{setRest(modifyArray(rest, ["tp", "tpl","tpm"]))}}
                    // onClick={()=>{setRest(["tp","tpm","tpl", "life"])}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${rest.includes("tp")?"bg-green-100 text-green-700 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Million Dollar Road Map
                </button>
                <button
                    onClick={()=>{setRest(modifyArray(rest, ["life"]))}}
                    // onClick={()=>{setRest(["life"])}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${rest.includes("life")?"bg-green-100 text-green-700 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Lifetime Membership
                </button>

            </span>

                    <button onClick={()=>updateDatabase()} disabled={buttonDisabled} className="flex flex-row bg-green-600 text-gray-200 py-2 px-4 w-full justify-center rounded-md focus:outline-none focus:border-green-700">Schedule Livestream</button>
                    </>)}
        
                  </div>
                </div>
        )}
        <button
            type="button"
            onClick={() => {setisOpen(true)}}
            className="relative flex flex-col items-center block min-w-[280px] rounded-lg border-2 border-dashed border-green-700 p-12 text-center hover:border-green-500 focus:outline-none text-green-700 hover:text-green-500 hover:bg-green-50"
        >
            <PlusCircleIcon className="h-12 justify-center "/>
            <span className="mt-2 block text-sm font-semibold text-gray-900">Schedule Livestream</span>
        </button>
        </>

    )
  }