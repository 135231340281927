import React, { useEffect, useState } from 'react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { createTask } from '../../util/db';

const TaskBuilderButton = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [title, setTitle] = useState('');
  const [buttonDisabled,setButtonDisabled] = useState(false)
  const [description, setDescription] = useState('');

  const [finalObj, setFinalObj] = useState({})


  function timeToSeconds(timeString, date = new Date()) {
    var hourMin = timeString.split(":");
    var hours = parseInt(hourMin[0], 10);
    var mins = parseInt(hourMin[1], 10);
  
    // Create a Date object for the given date and set the hours and minutes
    var dateObj = new Date(date);
    dateObj.setHours(hours);
    dateObj.setMinutes(mins);
    dateObj.setSeconds(0);
    dateObj.setMilliseconds(0);
  
    // Return the timestamp in seconds
    return Math.floor(dateObj.getTime() / 1000);
  }

  async function postTask() {
    setButtonDisabled(true)
    await createTask( {
        owner: props.uid,
        ...finalObj
    })
    setFinalObj(null)
    setTitle('')
    setStart('')
    setEnd('')
    setDescription('')
    props.onValueChange(null)

    setButtonDisabled(false)
  }

  useEffect(() => {

    if (start.length ===5 && end.length === 5) {
        props.onValueChange( {
            time: {seconds: timeToSeconds(start, new Date(props.selectedYear, props.selectedMonth, props.selectedDay))},
            duration: (timeToSeconds(end, new Date(props.selectedYear, props.selectedMonth, props.selectedDay)) - timeToSeconds(start, new Date(props.selectedYear, props.selectedMonth, props.selectedDay))) /60,
            title: title.length>0?title:"Untitled",
            description: description.length>0?description:"No description provided."
        })
        setFinalObj( {
            time: {seconds: timeToSeconds(start, new Date(props.selectedYear, props.selectedMonth, props.selectedDay))},
            duration: (timeToSeconds(end, new Date(props.selectedYear, props.selectedMonth, props.selectedDay)) - timeToSeconds(start, new Date(props.selectedYear, props.selectedMonth, props.selectedDay))) /60,
            title: title,
            description: description.length>0?description:"No description provided."
        })
    }

  },[start, end, title, description])
  return (
    <div className="relative inline-block">
      {isVisible && (
        <div className="absolute left-0 p-4 bg-white border border-yellow-500 rounded-lg z-10 transform -translate-x-full">
          <div className="flex flex-col">
            <div className="rounded-md px-3 pb-1.5 pt-2.5 m-1 shadow-sm ring-1 ring-inset ring-gray-300">
              <label htmlFor="title" className="block text-xs font-medium text-gray-900">
                Title
              </label>
              <input
                id="title"
                name="title"
                type="text"
                placeholder="Schedule Appointment"
                required
                className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="rounded-md px-3 pb-1.5 pt-2.5 m-1 shadow-sm ring-1 ring-inset ring-gray-300">
              <label htmlFor="description" className="block text-xs font-medium text-gray-900">
                {"Description (optional)"}
              </label>
              <div className="w-full">
                <input
                  type="text"
                  placeholder="Call Tim today. He needs help looking over some documents."
                  required
                  className="w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 truncate"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>

            <div className='flex flex-row'>
              <div className="rounded-md m-1 px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300">
                <label htmlFor="start" className="block text-xs font-medium text-gray-900">
                  Start Time
                </label>
                <input
                  id="start"
                  name="start"
                  type="time"
                  placeholder="13:15"
                  required
                  className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  value={start}
                  onChange={(e) => setStart(e.target.value)}
                />
              </div>
              <div className="rounded-md m-1 px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300">
                <label htmlFor="end" className="block text-xs font-medium text-gray-900">
                  End Time
                </label>
                <input
                  id="end"
                  name="end"
                  type="time"
                  placeholder="14:45"
                  required
                  className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  value={end}
                  onChange={(e) => setEnd(e.target.value)}
                />
              </div>
            </div>

            <button type='submit' disabled={buttonDisabled} onClick={() => postTask()} className=" py-1 bg-white-500 border border-green-700 text-green-700 rounded-full mt-2 w-full">Save Task</button>
            <button type='submit' onClick={() => {
                setIsVisible(!isVisible) 
                setFinalObj(null)
                props.onValueChange(null)
                setTitle('')
                setStart('')
                setEnd('')
                setDescription('')
            }} className=" py-1 bg-white-500 border border-red-700 text-red-700 rounded-full mt-2 w-full">Discard Task</button>

          </div>
        </div>
      )}
      <button onClick={() => {
        setIsVisible(!isVisible) 
        setFinalObj(null)
        props.onValueChange(null)
        setTitle('')
        setStart('')
        setEnd('')
        setDescription('')
      }} className="flex flex-row justify-center items-center w-full bg-yellow-50 border border-yellow-700 text-yellow-700 mx-2 mb-2 p-1 rounded-lg border-dashed">
        <PlusCircleIcon className="h-8" /> Add Task
      </button>
    </div>
  );
};

export default TaskBuilderButton;
