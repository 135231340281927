import { loadStripe } from "@stripe/stripe-js";
import { apiRequest } from "./util";
import { getStripePriceId } from "./prices";

let stripe;
// Load the Stripe script
loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
  // Pin to specific version of the Stripe API
  apiVersion: "2020-08-27",
}).then((stripeInstance) => {
  // Set stripe so all functions below have it in scope
  stripe = stripeInstance;
});

export async function redirectToCheckout(planIds) {
  // Create a checkout session
  const session = await apiRequest("stripe-create-checkout-session", "POST", {
    priceIds: planIds.map((each, index) => index !== 0 ? getStripePriceId(each) : each),
    successUrl: `${window.location.origin}/dashboard/dashboard?paid=true`,
    cancelUrl: `${window.location.origin}/pricing/plans`,
  });

  // Ensure if user clicks browser back button from checkout they go to /pricing
  // instead of this page or they'll redirect right back to checkout.
  window.history.replaceState({}, "", "/pricing/plans");

  // Redirect to checkout
  return stripe.redirectToCheckout({
    sessionId: session.id,
  });
}

export async function redirectToBilling() {
  // Create a billing session
  const session = await apiRequest("stripe-create-billing-session", "POST", {
    returnUrl: `${window.location.origin}/settings/subscriptions`,
  });
  // Ensure if user clicks browser back button from billing they go to /settings/subscriptions
  // instead of this page or they'll redirect right back to billing.
  window.history.replaceState({}, "", "/settings/subscriptions");

  // Redirect to billing session url
  window.location.href = session.url;
}
