import React from 'react';

const SmallCard = (props) => {
  return (


        <div className="rounded-lg p-4 max-w-sm w-full bg-gray-100">
          <div className="flex flex-col items-left">
            <h3 className="text-sm text-gray-400">{props.title}</h3>
            <p className="text-gray-600 text-sm mt-1">{props.description}</p>
          </div>
        </div>

)};


export default SmallCard;
