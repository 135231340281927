import React, { useState } from 'react';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { updateTeam, useUser } from '../../util/db';

const PersonCard = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const defaultAvatar = 'https://via.placeholder.com/150?text=Avatar';

  const {
    data: person,
    status: personStatus,
    error: personError
  } = useUser(props.uid)

  async function removeUser(teamId) {
    setButtonDisabled(true);
    var array = props.teamMembers.filter(element => element !== props.uid)
    

    await updateTeam(teamId, {
      members: array
    })
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="relative rounded-lg bg-gray-100 min-w-[280px]">
      <div className="flex flex-col items-start p-4 text-gray-800 hover:text-gray-600">
        {(personStatus === "success" && person)&& (
          <>
          <div className='flex flex-row items-center'>
          <img src={person.profilePhoto?person.profilePhoto:defaultAvatar} alt={person.name} className="h-16 w-16 rounded-md object-cover mr-4" />
          {(props.owner === props.uid)&&<span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
        Owner
      </span>}
          </div>
          <div className='w-full'>
            <div className="text-lg font-semibold mt-2">{person.name}</div>
            <a href={`mailto:${person.email}`}>
            <div className="text-sm text-gray-500 hover:underline">{person.email}</div>
            </a>
            <div className="my-4"></div>
            <p className='text-xs text-gray-500'>Webinar Completion Rate:</p>
            <div>
              <div className="mt-2 w-full " aria-hidden="true">
                <div className='flex flex-row space-x-2 items-baseline'>
                <p className='font-semibold text-green-700'>{person.finishedContent?(person.finishedContent.length*10):0}%</p>
                <p className='text-xs text-gray-500'>completed</p>
                </div>
                <div className="overflow-hidden rounded-full bg-gray-200">
                  <div className="h-2 rounded-full bg-green-700" style={{ width: `${person.finishedContent?(person.finishedContent.length*10):0}%` }} />
                </div>
              </div>
            </div>
          </div>
          </>
        )}

      </div>
      {(props.selfUid !== props.uid)&&<div className="absolute top-0 right-0 p-2">
        <button onClick={toggleMenu} className="text-gray-600 hover:text-gray-800 focus:outline-none">
        <EllipsisHorizontalIcon className='h-10'/>
        </button>
        {menuOpen && (
          <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20 divide-y">
            <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">View User Details</button>
            <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">Give Feedback</button>
            {(props.selfUid === props.owner)&&
              <button onClick={() => removeUser(props.teamId)} className="block px-4 py-2 text-sm text-red-700 hover:bg-gray-100 w-full text-left">Remove User</button>
            }
          </div>
        )}
      </div>}
    </div>
  );
};

export default PersonCard;
