import React, { useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { Link } from '../../util/router';

const SearchBox = ({ onSearch }) => {
  const [query, setQuery] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const indexLibrary = [
    {
      term: "Stairs and Buckets",
      href: "/tools/sb"
    },
    {
      term: "Sales Processes",
      href: "/tools/sp"
    },
    {
      term: "Webinars",
      href: "/tools/sp"
    },
    {
      term: "Trainings",
      href: "/tools/trainings"
    },
    // {
    //   term: "Blogs",
    //   href: "/blog"
    // },
    {
      term: "Subscriptions",
      href: "/settings/subscriptions"
    },
    {
      term: "Settings",
      href: "/settings/account"
    },
    {
      term: "Analytics",
      href: "/dashboard/analytics"
    },
    {
      term: "Teams",
      href: "/teams/all"
    },
    {
      term: "Help",
      href: "/help/chat"
    },
    {
      term: "Pricing",
      href: "/plans"
    },
    {
      term: "Cancel Subscription",
      href: "/settings/subscriptions"
    },
    {
      term: " Notification Settings",
      href: "/settings/notifications"
    },
    
  ]
  function indexResults(char) {
    var temp = []
    indexLibrary.forEach((object) => {
      if (` ${object.term.toLowerCase()}`.includes(` ${char.trim().toLowerCase()}`)) {
        temp.push(object)
      }
    })
    return temp
  }

  // Handle the search input change
  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  // Handle submitting the search
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  // Handle clicking on the search box
  const handleInputClick = () => {
    setShowDropdown(true);
  };

  // Handle clicking outside to close the dropdown
  const handleClickOutside = (event) => {
    if (!event.target.closest('.search-container')) {
      setShowDropdown(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className='search-container border border-gray-300 px-2 rounded-md relative'>
      <form className="flex items-center space-x-2" onSubmit={handleSubmit}>
        <div className="flex items-center rounded-md">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-500 ml-2" />
          <input
            type="text"
            value={query}
            onChange={handleChange}
            onClick={handleInputClick}
            placeholder="Search for anything"
            className="py-2 px-4 border-none focus:outline-none focus:ring-0 focus:border-transparent"
          />
        </div>
      </form>
      {(showDropdown && query.trim().length >0) && (
        <div className="absolute top-full left-0 right-0 mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-40 overflow-scroll">
          <ul>
            {indexResults(query).map((each, i) => 
            <Link key={i} to={each.href}> 
              <li className="px-4 py-2 hover:bg-gray-100">{each.term}</li>
            </Link>)}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchBox;
