import React from "react";
import Meta from "../../components/Meta";
import MemberViewSection from "../../components/Custom/MemberViewSection";
import SideNav from "../../components/Custom/SideNav";
import { requireAuth } from "../../util/auth";


function MemberViewPage(props) {
  return (
    <>
      <Meta title="Private" />
      <MemberViewSection/>
      <SideNav current = "tools"/>
    </>
  );
}

export default requireAuth(MemberViewPage);
