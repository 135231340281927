import { useEffect, useState } from "react"
import { useTeamsByMembership } from "../../util/db"
import { Link } from "../../util/router"
import PeopleCardProgressEntry from "./PeopleCardProgressEntry"
import { PlusCircleIcon, PencilIcon } from "@heroicons/react/24/outline"

  export default function PeopleProgressCard(props) {
    const [empty, setEmpty] = useState(true)
    const teams = props.teams

    useEffect(() => {
      if (teams) {
        teams.forEach(team => {
          if (team.members && team.members.length >1) {
            setEmpty(false)
          }
        })
      }
    },[teams])

    return (
        <div className="rounded-lg border-solid border border-green-600">
      <ul className="">
        {(teams&&teams.length>0 &&!empty)&&(<>
        {teams.map((team, index) => (team.members.map((member, i) => 
          <div key = {member}>
          <PeopleCardProgressEntry uid = {member} teamName = {team.name} teamId ={team.id}/>
          </div>
          
        )
        ))}</>)}

        {(empty &&teams&& teams.length>0)&& (
           <>
           <div className="flex p-4 text-center align-center w-full justify-center">
               <div className="flex flex-col items-center">
               <p className="text-md font-semibold leading-6 text-gray-900">
                  Looks like you are the only member within your teams...
               </p>
               <Link to ="/teams/all">
               <button
                  type="button"
                  className="relative flex flex-col items-center rounded-lg border border-dashed border-green-700 p-4 text-center hover:border-green-500 focus:outline-none text-green-700 hover:text-green-500 hover:bg-green-50"
              >
                  <PencilIcon className="h-12 justify-center "/>
                  <span className="mt-2 text-sm font-semibold text-gray-900">Manage Teams</span>
              </button>
              </Link>
               </div>
           </div>
           </>
        )}

        {(teams&& teams.length===0)&& (
           <>
           <div className="flex p-4 text-center align-center w-full justify-center">
               <div className="flex flex-col items-center">
               <p className="text-md font-semibold leading-6 text-gray-900">
                   Looks like you aren't in any teams...
               </p>
               <Link to ="/teams/all">
               <button
                  type="button"
                  className="relative flex flex-col items-center rounded-lg border border-dashed border-green-700 p-4 text-center hover:border-green-500 focus:outline-none text-green-700 hover:text-green-500 hover:bg-green-50"
              >
                  <PlusCircleIcon className="h-12 justify-center "/>
                  <span className="mt-2 text-sm font-semibold text-gray-900">Create A Team</span>
              </button>
              </Link>
               </div>
           </div>
           </>
        )}
      </ul>
        </div>

    )
  }
  