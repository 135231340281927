import React from 'react';

const LargeCard = (props) => {
  return (


        <div className="rounded-lg p-4 w-full border border-solid border-gray-200">
          <div className="flex flex-col items-left">
            {props.titleDescriptions.map((each, key) => {
                return <div key = {key}>
                <h3 className="text-2xl font-semibold">{each.title}</h3>
                <p className="text-gray-400 text-md mt-1">{each.description}</p>
                <div className='w-full border-gray-200 border-t my-6'/>
                </div>
            })}
            {props.children}
          </div>
        </div>

)};


export default LargeCard;