import React from "react";
import CustomLandingSection from "../../components/Custom/CustomLandingSection";


function LandingPage(props) {

  return (
    <>
        <CustomLandingSection/>
    </>
  );
}

export default LandingPage;
