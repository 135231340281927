import React from 'react';

const FrontHeader = (props) => {
  return (
    <>
        <p className='text-center text-5xl font-semibold text-green-700'>{props.text}</p>
        <div className='border-b my-4 mx-32 border-green-700'></div>
    </>
  );
};

export default FrontHeader;
