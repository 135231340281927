// MarkdownRenderer.js
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const MarkdownRenderer = ({ markdown }) => {
  const markdownStyles = {
    ul: {
      listStyleType: 'disc',
      marginLeft: '20px',
    },
    ol: {
      listStyleType: 'decimal',
      marginLeft: '20px',
    },
    li: {
      marginBottom: '5px',
    },
  };

  return (
    <div style={{ ...markdownStyles }}>
      <ReactMarkdown remarkPlugins={[remarkGfm]} components={{
        ul: ({ node, ...props }) => <ul style={markdownStyles.ul} {...props} />,
        ol: ({ node, ...props }) => <ol style={markdownStyles.ol} {...props} />,
        li: ({ node, ...props }) => <li style={markdownStyles.li} {...props} />,
      }}>
        {markdown}
      </ReactMarkdown>
    </div>
  );
};

export default MarkdownRenderer;
