import React from "react";
import Meta from "../../components/Meta";
import { requireAuth } from "../../util/auth";
import SalesProcessSection from "../../components/Custom/SalesProcessSection";

function TrainingPage(props) {
  return (
    <>
      <Meta title="Tools" />
      <SalesProcessSection/>
    </>
  );
}

export default requireAuth(TrainingPage);
