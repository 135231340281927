import React from "react";
import { Link } from "../../util/router";
export default function ToggleButton(props) {

 return (
<nav className={`flex items-center border-b border-gray-200 mx-4 ${props.fullwidth&&("w-full")}`}>
        {props.sectionsArray.map((tab) => (
          <Link
            to={`${props.urlPrefix}/${tab.section}`}
            className={
              "flex items-center grow justify-center px-3 md:px-4 -mb-px py-4 border-b-2" +
              (tab.section === props.section
                ? " text-green-500 border-green-500"
                : "") +
              (tab.section !== props.section
                ? " text-gray-400 border-transparent hover:text-green-500 hover:border-green-500"
                : "")
            }
            key={tab.section}
          >
            {tab.label}
          </Link>
        ))}
      </nav>
 )
}

