import React, { useState } from 'react';

function CopyTextComponent({ textToCopy }) {
  const [copied, setCopied] = useState(false);

  function handleCopy() {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
    } else {
      // Fallback method
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <button
      className="pr-6 text-sm cursor-pointer text-blue-500 hover:underline bg-transparent p-0"
      onClick={() => handleCopy()}
    >
      {copied ? 'Copied!' : 'Copy Link'}
    </button>
  );
}

export default CopyTextComponent;
