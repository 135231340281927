import React from "react";
import { useRouter } from "../../util/router";
import { requireAuth } from "../../util/auth";

import MultiStepForm from "../../components/Custom/MultiStepForm";

function InfoPage(props) {
  const router = useRouter();

  return (
    <>
      {(router.query.type==="new")&&(
        <MultiStepForm/>
      )}
    </>
  );
}

export default requireAuth(InfoPage);
