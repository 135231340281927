import React from 'react';

const TitleCard = (props) => {
  return (
    <div className='mb-6'>
        <div className="flex justify-between items-center m-4">
        <div>
          <h3 className='text-2xl font-semibold'>{props.title}</h3>
          <p className="text-gray-600">{props.subtitle}</p>
        </div>

      </div>
      {props.children}
    </div>
  );
};


export default TitleCard;
