import React, { useState } from "react";
import Section from "../Section";
import { useAuth } from "../../util/auth";
import SearchBox from "./SearchBox";
import {
  ChevronDownIcon,
  Squares2X2Icon,
  CogIcon,
  LockClosedIcon,
  UserCircleIcon,
  ArrowLeftOnRectangleIcon,
  BellIcon    
} from "@heroicons/react/24/solid";
import { Menu, Transition } from "@headlessui/react";
import { Link, useRouter } from "../../util/router";
import Button from "../Button";
import Navbar from "../Navbar";
import InboxSection from "./InboxSection";

function ApplicationTop(props) {
  const auth = useAuth();
  const router = useRouter()
  const [newMessage, setNewMessage] = useState(false)
  const [isInboxVisible, setInboxVisible] = useState(false);
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

  const toggleInbox = () => {
    setInboxVisible(!isInboxVisible);
    setNewMessage(false)
  };

  const handleNewMessage = (value) => {
    setNewMessage(value);
  };

  function getFirstWord(path) {
    const words = path.split('/').filter(word => word !== '');
    return words.length > 0 ? words[0] : '';
  }
  const classes = {
    navLink:
      "font-semibold inline-flex items-center space-x-1 h-8 px-4 group-hover:text-green-500 py-6",
    navLinkIcon:
      "opacity-50 transform transition duration-200 ease-out group-hover:rotate-180 inline-block w-4 h-4",
    dropdown: {
      base: "absolute top-19 pt-1 z-10 invisible group-hover:visible transform transition duration-800 ease-in opacity-0 group-hover:opacity-100",
      left: "right-0",
      center: "left-1/2 -translate-x-1/2",
      right: "left-0",
      normal: "w-48",
      large: "w-96",
      inner:
        "bg-white shadow-xl ring-1 ring-black ring-opacity-5 rounded-lg overflow-hidden",
      title:
        "text-xs uppercase font-semibold tracking-wider text-blue-800 mb-5",
      link: "text-gray-600 hover:text-blue-600 font-medium text-sm flex items-center space-x-2",
      icon: "opacity-25 inline-block w-5 h-5",
      feature:
        "p-3 rounded-xl flex items-center space-x-4 text-gray-600 font-medium text-sm",
      featureName: "font-semibold mb-1",
      featureDescription: "opacity-75",
    },
    accountDropdown: {
      base: "absolute right-0 origin-top-right mt-2 w-48 z-10",
      inner:
        "bg-white ring-1 ring-black ring-opacity-5 rounded divide-y divide-gray-100 shadow-xl rounded",
      link: "flex items-center space-x-2 rounded py-2 px-3 text-sm font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-700",
      linkActive: "text-gray-700 bg-gray-100",
      linkInactive:
        "text-gray-600 hover:bg-gray-100 hover:text-gray-700 focus:outline-none focus:bg-gray-100 focus:text-gray-700",
      icon: "opacity-50 inline-block w-5 h-5",
    },
  };
  
  return (
    <>
    {router.pathname.includes("/view/")&& !router.pathname.includes("/view/member")?<></>:
    <>
    {(auth.user&& ["dashboard", "settings", "teams", "calendar", "help", "tools", "admin"].includes(getFirstWord(router.pathname)))?
    <>
    <div className="hidden lg:block">
    <Section bgColor={props.bgColor}>
    <div className="container py-4 z-20">
      <div className="flex justify-between items-center">
      <div className="flex flex-col px-4">
        {getFirstWord(router.pathname)==="dashboard"?<>
            <p className="text-lg text-gray-500">Welcome,</p>
            <p className="text-5xl font-semibold text-green-700">{(auth.user)?auth.user.name?auth.user.name:auth.user.email:"Loading"}</p>
            </>:<p className="text-5xl font-semibold">{getFirstWord(router.pathname)==="admin"?"Admin Dashboard":capitalizeFirstLetter(getFirstWord(router.pathname))}</p>}
          
      </div>
      <div className="flex flex-row space-x-4 items-center">
          <SearchBox/>
          <div className="relative">
          <button
              type="button"
              onClick={toggleInbox}
              className="rounded-md bg-gray-200 p-2.5 text-black"
          >
              <BellIcon className="h-5 " aria-hidden="true" />
          {(newMessage)&&<span className="absolute right-0 top-0 block h-3.5 w-3.5 rounded-full bg-red-400 ring-2 ring-white" />}

          </button>
            <div className={`${!(isInboxVisible||newMessage)?"hidden":""} absolute top-12 right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}>
              <div className="p-4">
                <h3 className="text-lg font-medium text-gray-900">Inbox</h3>
                <ul className="mt-2 text-sm text-gray-700">
                  <InboxSection onValueChange = {handleNewMessage}/>
                </ul>
              </div>
            </div>
          </div>


          {(auth.user)&&<Menu as="div" className="relative inline-block">
              <Button
                  className="border"
                component={Menu.Button}
                variant="simple"
                size="sm"
                endIcon = {<ChevronDownIcon className="opacity-50 inline-block w-5 h-5" />
              }
              >
                <UserCircleIcon className="h-10"/>
              </Button>
              <Transition
                as={React.Fragment}
                enter="transition ease-out duration-150"
                enterFrom="transform opacity-0 scale-75"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-100"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-75"
              >
                <Menu.Items className={classes.accountDropdown.base}>
                  <div className={`${classes.accountDropdown.inner}`}>
                    <div className="p-2 space-y-1">
                      <Menu.Item>
                        <Link
                          to="/dashboard/dashboard"
                          className={`${classes.accountDropdown.link}`}
                        >
                          <Squares2X2Icon
                            className={classes.accountDropdown.icon}
                          />
                          <span>Dashboard</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          to="/settings/account"
                          className={`${classes.accountDropdown.link}`}
                        >
                          <CogIcon className={classes.accountDropdown.icon} />
                          <span>Settings</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          to="/"
                          className={`${classes.accountDropdown.link}`}
                        >
                          <ArrowLeftOnRectangleIcon
                            className={classes.accountDropdown.icon}
                          />
                          <span>Exit Application</span>
                        </Link>
                      </Menu.Item>
                    </div>
                    <div className="p-2 space-y-1">
                      <Link
                        to="#"
                        className={`${classes.accountDropdown.link}`}
                        onClick={(e) => {
                          e.preventDefault();
                          auth.signout();
                        }}
                      >
                        <LockClosedIcon
                          className={classes.accountDropdown.icon}
                        />
                        <span>Sign out</span>
                      </Link>
                    </div>
                  </div>
                </Menu.Items>
              </Transition>
          </Menu>}
      </div>
      </div>
    </div>
    </Section>
    </div>
    <div className="block lg:hidden flex flex-row items-center w-full justify-between">
    <div className="w-full">
    <Navbar/>
    </div>
    <button
              type="button"
              onClick={toggleInbox}
              className="relative rounded-md bg-gray-200 p-2.5 mr-4 text-black h-min w-min"
          >
              <BellIcon className="h-5 " aria-hidden="true" />
          {(newMessage)&&<span className="absolute right-0 top-0 block h-3.5 w-3.5 rounded-full bg-red-400 ring-2 ring-white" />}

          </button>
            <div className={`${!isInboxVisible?"hidden":""} absolute top-12 z-10 right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}>
              <div className="p-4">
                <h3 className="text-lg font-medium text-gray-900">Inbox</h3>
                <ul className="mt-2 text-sm text-gray-700">
                  <InboxSection onValueChange = {handleNewMessage}/>
                </ul>
              </div>
            </div>
    </div>
    </>
    :
     <Navbar/>  
    }

    </>}
    </>
  );
}

export default ApplicationTop;
