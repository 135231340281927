import React from 'react';
import { ArrowUpRightIcon } from '@heroicons/react/24/solid';
import { Link } from '../../util/router';

const LinkCard = (props) => {
  return (
    <div className='m-2'>
        <div className="flex justify-between items-center m-4">
        <div>
          <h3 className='text-2xl font-semibold'>{props.title}</h3>
          <p className="text-gray-600">{props.subtitle}</p>
        </div>
        {(props.href?
          <Link to ={props.href?props.href:"#"}>
          <button className="flex flex-row px-4 py-2 bg-gray-white text-green-500 rounded-md">
          View All
          <ArrowUpRightIcon className='h-5'/>
        </button>
          </Link>:<></>)}
      </div>
      {props.children}
    </div>
  );
};


export default LinkCard;
