import React from "react"
import { useState } from "react"
import {PlusCircleIcon, CakeIcon} from "@heroicons/react/24/solid";
import { createLiveWebinar } from "../../util/db";

export default function PostWebinar() {
    const [isOpen, setisOpen] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [embed, setEmbed] = useState("")
    const [title, setTitle] = useState("")
    const [subtitle, setSubtitle] = useState("")
    const [linkedResources, setLinkedResources] = useState([])
    const [successfullPost, setSuccessfullPost] = useState(false)


    function toggleArray(array, element) {
        if (array.includes(element)) {
            var t = array.filter(each => each !== element)
            return t
        } else {
            return [...array, element]
        }
    }


    function closePopup() {
        setisOpen(false)
        setSuccessfullPost(false)
        setButtonDisabled(false)
      }
    async function updateDatabase() {
        setButtonDisabled(true)
        await createLiveWebinar({
            embed: embed,
            title: title,
            subtitle: subtitle,
            requiredPlans: ["life","tp","tpl","tpm"],
            linkedResources: linkedResources
        })
        setSuccessfullPost(true)
        setButtonDisabled(false)
    }
    return (
        <>
        {(isOpen)&&(
                <div className="z-20 fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
                  {/* Popup content */}
                  <div className="bg-white p-8 rounded-lg shadow-lg lg:w-1/2 w-3/4 max-w-4xl relative">
                    {successfullPost===true?(
                    <div className="flex flex-col text-center space-y-4">
                    <CakeIcon className='h-12 text-green-700'/>
                    <h2 className='text-5xl font-semibold'>Webinar Posted Successfully</h2>
                    <p className='text-lg font-semithin'>This webinar is now viewable in the corresponding location in "Tools."</p>
                    <button onClick={()=> closePopup()} className=" absolute top-0 right-4 text-3xl text-gray-400 rounded-md">
                    &times;
                  </button>
                  </div>
                  ):(
                    <>
                    <h2 className=" text-center text-5xl font-bold pb-6">Post Webinar</h2>
                    <p className='text-gray-600 text-center'>Please enter the relevant information before posting.</p>
                    <button onClick={()=> closePopup()} className=" absolute top-0 right-4 text-3xl text-gray-400 rounded-md">
                      &times;
                    </button>

                    <div className='h-0 my-4 border-t-4 border-dotted border-gray-200'></div>
                    <p className='text-gray-400 text-left m-2'>Webinar Title</p>
                    <div className='mb-2'>
                        <input
                            type="text"
                            name="title"
                            id="title"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder='A Fitting Title'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <p className='text-gray-400 text-left m-2'>Webinar Subtitle</p>
                    <div className='mb-2'>
                        <input
                            name="subtitle"
                            id="subtitle"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder='This is the webinar subtitle'
                            value={subtitle}
                            onChange={(e) => setSubtitle(e.target.value)}
                        />
                    </div>
                    <p className='text-gray-400 text-left m-2'>Vimeo Embed Code</p>
                    <div className='mb-2'>
                        <input
                            name="embed"
                            id="embed"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder='892301237'
                            value={embed}
                            onChange={(e) => setEmbed(e.target.value)}
                        />
                    </div>
                    <p className='text-green-700 text-left m-2 text-sm'>Please note, the webinar embed code is not the whole copied value from vimeo. The embed code is a somewhat short alphanumeric sequence included in the url of the vimeo url inside the embeddable html.</p>


                    <p className='text-gray-400 text-left m-2'>Select where this webinar should be visible.</p>
            <span className="isolate rounded-md mb-2">
            <button
                    onClick={()=>{setLinkedResources(toggleArray(linkedResources, "sb"))}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${linkedResources.includes("sb")?"bg-green-100 text-green-700 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Stairs & Buckets
                </button>
                <button
                    onClick={()=>{setLinkedResources(toggleArray(linkedResources, "proc1"))}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${linkedResources.includes("proc1")?"bg-green-100 text-green-700 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Medicare
                </button>
                <button
                    onClick={()=>{setLinkedResources(toggleArray(linkedResources, "proc2"))}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${linkedResources.includes("proc2")?"bg-green-100 text-green-700 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Premium Life
                </button>
                <button
                    onClick={()=>{setLinkedResources(toggleArray(linkedResources, "proc3"))}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${linkedResources.includes("proc3")?"bg-green-100 text-green-700 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Finding the Money
                </button>
                <button
                    onClick={()=>{setLinkedResources(toggleArray(linkedResources, "proc4"))}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${linkedResources.includes("proc4")?"bg-green-100 text-green-700 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Protect and Participate
                </button>
                <button
                    onClick={()=>{setLinkedResources(toggleArray(linkedResources, "proc5"))}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${linkedResources.includes("proc5")?"bg-green-100 text-green-700 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Understanding Money
                </button>
                <button
                    onClick={()=>{setLinkedResources(toggleArray(linkedResources, "proc6"))}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${linkedResources.includes("proc6")?"bg-green-100 text-green-700 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Liability Wall
                </button>
                <button
                    onClick={()=>{setLinkedResources(toggleArray(linkedResources, "shuff"))}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${linkedResources.includes("shuff")?"bg-green-100 text-green-700 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Service Webinars
                </button>
                <button
                    onClick={()=>{setLinkedResources(toggleArray(linkedResources, "sp-guests"))}}
                    type="button"
                    className={`relative inline-flex items-center rounded-full ${linkedResources.includes("sp-guests")?"bg-green-100 text-green-700 ring-green-600":"bg-white text-gray-400 ring-gray-300"} px-3 py-2 mr-2 text-sm  ring-1 ring-inset  hover:bg-gray-50 focus:z-10`}
                >
                    Special Guests
                </button>

            </span>

                    <button onClick={()=>updateDatabase()} disabled={buttonDisabled} className="flex flex-row bg-green-600 text-gray-200 py-2 px-4 w-full justify-center rounded-md focus:outline-none focus:border-green-700">Upload Webinar</button>
                    </>)}
        
                  </div>
                </div>
        )}
        <button
            type="button"
            onClick={() => {setisOpen(true)}}
            className="relative flex flex-col items-center block min-w-[280px] rounded-lg border-2 border-dashed border-green-700 p-12 text-center hover:border-green-500 focus:outline-none text-green-700 hover:text-green-500 hover:bg-green-50"
        >
            <PlusCircleIcon className="h-12 justify-center "/>
            <span className="mt-2 block text-sm font-semibold text-gray-900">Upload Webinar</span>
        </button>
        </>

    )
  }