import React from "react";
import Meta from "../../components/Meta";
import ClientViewSection from "../../components/Custom/ClientViewSection";

function ClientViewPage(props) {
  return (
    <>
      <Meta title="Private" />
      <ClientViewSection/>
    </>
  );
}

export default ClientViewPage;
