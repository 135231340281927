import React from "react";
import LeadForm from "../../../components/Custom/LeadForm";
import TitleCard from "../../../components/Custom/TitleCard";
import Meta from "../../../components/Meta";
import TempLandingPageSection from "../../../components/Custom/TempLandingPageSection";

function TempLandingPage(props) {

  return (
    <>
        <Meta title="Promotional Landing" />
        
        <TempLandingPageSection/>

    </>
  );
}

export default TempLandingPage;
