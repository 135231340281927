import { useUserOnce } from "../../util/db"

  export default function PeopleCardEntry(props) {

    const defaultAvatar = 'https://via.placeholder.com/150?text=Avatar';

    const {
      data: person,
      error: personError,
      status: personStatus 
    }= useUserOnce(props.uid)


    return (
          <li className="relative flex justify-between rounded-lg gap-x-6 py-5 hover:bg-green-100 border">
            {(person && personStatus==="success")&&(
                <>
                <div className=" ml-4 flex min-w-0 gap-x-4 ">
                    <img alt="" src={person.profilePhoto? person.profilePhoto: defaultAvatar} className="h-12 w-12 flex-none rounded-full bg-gray-50" />
                    <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                        <a href={`/teams/${props.teamId}`}>
                        <span className="absolute inset-x-0 -top-px bottom-0" />
                        {person.name}
                        </a>
                    </p>
                    <p className="mt-1 flex text-xs leading-5 text-gray-500">
                        <a href={`mailto:${person.email}`} className="relative truncate hover:underline">
                        {person.email}
                        </a>
                    </p>
                    </div>
                </div>
                <div className=" mr-4 flex shrink-0 items-center gap-x-4">
                    <div className="hidden sm:flex sm:flex-col sm:items-end">
                    <p className="text-sm leading-6 text-gray-900">{props.teamName}</p>
                    </div>
                </div>
                </>
            )}
          </li>
    )
  }