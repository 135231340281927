import React, { useState } from "react";
import SideNav from "./SideNav";
import LargeCard from "./LargeCard";
import { useAllUsers, useUnapprovedTestimonials, deleteTestimonial, updateTestimonial } from "../../util/db";
import CSVExporter from "./CSVExporter";
import { Menu } from '@headlessui/react'
import { UserCircleIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import LoadingIcon from "../LoadingIcon";
import { getStripePriceId } from "../../util/prices";

const columns = ["Name", "Email", "Phone Number", "Company", "Subscription(s)"]
const allArray = [
  getStripePriceId("sbm"),
  getStripePriceId("sby"),
  getStripePriceId("sbm1"),
  getStripePriceId("tp"),
  getStripePriceId("tpm"),
  getStripePriceId("tpl"),
  getStripePriceId("proc"),
  getStripePriceId("procm"),
  getStripePriceId("life"),
]

function CRMSection(props) {
  function toggleElementsInList(list, elements) {
    let result = [...list]; // Work with a copy of the original list
  
    for (const element of elements) {
      const index = result.indexOf(element);
      if (index === -1) {
        // Element not found, add it
        result = [...result, element];
      } else {
        // Element found, remove it
        result = [...result.slice(0, index), ...result.slice(index + 1)];
      }
    }
  
    return result;
  }
  function haveCommonElement(arr1, arr2) {
    // Convert the first array into a set for O(1) lookups
    const set1 = new Set(arr1);
  
    // Check each element in arr2 against the set
    for (const element of arr2) {
      if (set1.has(element)) {
        return true;
      }
    }
  
    return false;
  }

  function filterPeopleByPlans(people, plans) {
    if (!plans.includes("extraneous")) {
      return people.filter((each) => haveCommonElement(each.stripePriceIds, plans))
    } else {
      return people.filter((each) => (!haveCommonElement(each.stripePriceIds, allArray) && each.stripePriceIds && each.stripePriceIds?.length >0))
    }
  }
  const [planFilters, setPlanFilters] = useState([])
  const [planOpen, setPlanOpen] = useState(false)
  const [filteredPeople, setFilteredPeople] = useState(false)

  const {
    data: testimonials,
    status: testimonialsStatus,
    error: testimonialsError
  } = useUnapprovedTestimonials()
  const {data: people,
    status: peopleStatus,
    error: peopleError,
  } = useAllUsers()


//   const peopleStatus = "success"
//   const people = [{
//     "id": "024huWtGPmcnoGdIldWoaxOchP53",
//     "legacyInterval": "year",
//     "stripeCustomerId": "cus_Q6gX6QK6RCweaS",
//     "email": "trevor@insurewithtink.com",
//     "importResolutionRequired": true,
//     "legacyPlans": [
//         "sby"
//     ],
//     "legacyRecentPayment": {
//         "seconds": 1728274946,
//         "nanoseconds": 798000000
//     }
// }]

  return (
    <>
      <div className="container">
      <div className="flex w-full">

        <div className="flex-grow px-4">
          <>
          {(testimonials && testimonialsStatus === "success" && testimonials.length >0)&&
          <LargeCard titleDescriptions  ={[{title: "Testimonials Awaiting Approval", description: "Approve testimonials before they go live."}]}>
        {(testimonials && testimonialsStatus === "success" && testimonials.length>0)?testimonials.map((testimonial, index) => (
            <>
            <div className="flex flex-row items-center">
              <div className="flex flex-col">
                <button onClick={() => updateTestimonial(testimonial.id, {approved: true})} className="bg-green-700 text-white h-min m-2 p-2 rounded-lg">Approve Testimonial</button>
                <button onClick={() => deleteTestimonial(testimonial.id)} className="bg-red-700 text-white h-min m-2 p-2 rounded-lg">Delete Testimonial</button>
              </div>
            {(testimonial.type === "text")&&
            <div
              className="flex-1 border rounded-md border-green-700 relative min-w-[300px] max-w-full"
              key={index}
            >
              <div className="absolute top-0 right-0 text-8xl mt-1 mr-2 text-green-700 opacity-75 font-serif">
                “
              </div>
              <div className="px-4 pt-14 pb-6 sm:px-6 sm:pb-6 relative">
                <blockquote>
                <p className="leading-7 mb-2 font-bold">{testimonial.title}</p>
                  <p className="leading-7 mb-5">{testimonial.description}</p>
                  <footer className="flex items-center space-x-4">
                    <UserCircleIcon className="h-16" />
                    <div>
                      <span className="font-semibold text-green-700">
                        {testimonial.display_name}
                      </span>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
            }
            </div>

            {(testimonial.type === "video")&&<div>This would be a video</div>}
            </>
          )):
          <div className="flex w-full justify-center items-center">
          {(testimonialsStatus !== "success")&&<LoadingIcon className="h-12"/>}
          </div>
          }
        </LargeCard>}
        <div className="flex w-full pt-2">
        <LargeCard titleDescriptions = {[{title: "Customers", description: "Here you can view and manage our customers..."}]}>
        {(peopleStatus === "success" && people && people.length >0)&& (
        <div className="p-1 divide-solid divide-y">
          <div className="w-full flex justify-end mb-2">
            {filteredPeople&&<CSVExporter data = {filteredPeople}/>}
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button onClick={() => setPlanOpen(!planOpen)} className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                Filter By Plan
                <ChevronDownIcon aria-hidden="true" className="-mr-1 h-6 w-6 text-gray-400" />
              </Menu.Button>
            </div>

            {planOpen&&<Menu.Items
              static
              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5"
            >
              <div className="py-1">
                <Menu.Item>
                  <button
                    onClick={() => setPlanFilters(toggleElementsInList(planFilters, [getStripePriceId("life")]))}
                    className={`block px-4 py-2 text-sm text-gray-700 mx-2 ${planFilters.includes(getStripePriceId("life"))&&"border rounded-md bg-green-100 border-green-700 text-green-700"}`}
                  >
                    Million Dollar Membership
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={() => setPlanFilters(toggleElementsInList(planFilters, [getStripePriceId("tp"),getStripePriceId("tpm"),getStripePriceId("tpl")]))}
                    className={`block px-4 py-2 text-sm text-gray-700 mx-2 ${planFilters.includes(getStripePriceId("tp"))&&"border rounded-md bg-green-100 border-green-700 text-green-700"}`}
                  >
                    Million Dollar Road Map
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={() => setPlanFilters(toggleElementsInList(planFilters, [getStripePriceId("proc"),getStripePriceId("procm")]))}
                    className={`block px-4 py-2 text-sm text-gray-700 mx-2 ${planFilters.includes(getStripePriceId("proc"))&&"border rounded-md bg-green-100 border-green-700 text-green-700"}`}
                  >
                    Million Dollar Toolkit
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={() => setPlanFilters(toggleElementsInList(planFilters, [getStripePriceId("sbm"),getStripePriceId("sby"),getStripePriceId("sbm1"),]))}
                    className={`block px-4 py-2 text-sm text-gray-700 mx-2 ${planFilters.includes(getStripePriceId("sby"))&&"border rounded-md bg-green-100 border-green-700 text-green-700"}`}
                  >
                    Million Dollar Startup
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={() => setPlanFilters(toggleElementsInList(planFilters, ["extraneous"]))}
                    className={`block px-4 py-2 text-sm text-gray-700 mx-2 ${planFilters.includes("extraneous")&&"border rounded-md bg-green-100 border-green-700 text-green-700"}`}
                  >
                    Others
                  </button>
                </Menu.Item>
                  <Menu.Item>
                    <button
                    onClick={()=>{
                      setPlanOpen(false)
                      setFilteredPeople(filterPeopleByPlans(people, planFilters))
                    }}
                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                    >
                      Apply Filter
                    </button>
                  </Menu.Item>
              </div>
            </Menu.Items>}
          </Menu>
          </div>
        <div className="flex justify-between py-2 px-2 font-bold">
        {columns.map((each, k) => 
        <div key = {each} className={`w-1/6 ${k===0?"text-start":"text-center"}`}>{each}</div>
        )}
        </div>

        {(filteredPeople?filteredPeople:people).map((person, k) => (
            <div key={person.id} className={`flex justify-between py-2 px-2 text-sm text-gray-500 ${k %2===0?"bg-green-50":""}`}>
            <div className="w-1/6 text-start">{person.name?person.name:"Not Provided"}</div>
            <div className="w-1/6 text-center">{person.email?person.email: "Not Provided"}</div>
            <div className="w-1/6 text-center">{person.phone?person.phone: "Not Provided"}</div>
            <div className="w-1/6 text-center">{person.company?person.company: "Not Provided"}</div>
            <div className="w-1/6 text-center">
            {person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_LIFE)?"Lifetime Membership ":""}
            {person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_TP)||person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_TPL)?"Million Dollar Road Map ":""}
            {person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_TPM)?"Million Dollar Road Map Monthly ":""}
            {person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_PROCM)?"Million Dollar Tool Kit Monthly ":""}
            {person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_PROC)?"Million Dollar Tool Kit ":""}
            {(person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_SBM) ||person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_SBY) ||person.stripePriceIds?.includes(process.env.REACT_APP_STRIPE_PRICE_SBM1))?"Million Dollar Stairs and Buckets Start-Up ":""}
            {person.stripePriceIds || person.legacyPlans?"": "No Subscription"}
            {person.legacyPlans?.includes("life")?"Lifetime Membership Legacy ":""}
            {person.legacyPlans?.includes("tp")?"Trainings Package Legacy ":""}
            {person.legacyPlans?.includes("proc")?"Processes Package Legacy ":""}
            {(person.legacyPlans?.includes("sbm") ||person.legacyPlans?.includes('sby'))?"Stairs and Buckets Legacy ":""}
            </div>
            </div>
        ))}
        </div>
        )}
            </LargeCard>
        </div>

        </>
        </div>
    </div>

      </div>
      <SideNav current = "admin"></SideNav>

    </>

  );
}

export default CRMSection;
