import { apiRequest } from "./util";


export async function getChatCompletion(message) {
    try {
      const response = await apiRequest('ai-chat', 'POST', {
        action: 'createChatCompletion',
        data: {message}
      });
      return response;
    } catch (error) {
      console.error('Error fetching chat completion:', error);
      throw error;
    }
  }

  export async function getGPTThread(thread) {
    try {
        const response = await apiRequest('ai-chat', 'POST', {
          action: 'getThread',
          data: {thread}
        });
        return response;
      } catch (error) {
        console.error('Error fetching thread:', error);
        throw error;
      }
  }

  export async function createGPTMessage(message, thread) {
    try {
        const response = await apiRequest('ai-chat', 'POST', {
          action: 'createMessage',
          data: {message, thread}
        });
        return response;
      } catch (error) {
        console.error('Error fetching thread:', error);
        throw error;
      }
  }

  export async function deleteGPTThread(thread) {
    try {
        const response = await apiRequest('ai-chat', 'POST', {
          action: 'deleteThread',
          data: { thread}
        });
        return response;
      } catch (error) {
        console.error('Error deleting thread:', error);
        throw error;
      }
  }


  export async function createGPTThread() {
    try {
      const response = await apiRequest('ai-chat', 'POST', {
        action: 'createThread',
      });
      return response;
    } catch (error) {
      console.error('Error creating thread:', error);
      throw error;
    }
  }

  