import { useCodesByOwner, useUserOnce } from "../../util/db"

  export default function PeopleCardProgressEntry(props) {

    const defaultAvatar = 'https://via.placeholder.com/150?text=Avatar';

    const {
      data: person,
      error: personError,
      status: personStatus 
    }= useUserOnce(props.uid)

    const {
        data: codes,
        error: codesError,
        status: codesStatus
    } = useCodesByOwner(props.uid)



    return (
          <li className="relative grid grid-cols-3 sm:grid-cols-4 items-center rounded-lg gap-x-6 py-5 hover:bg-green-100">
            {(person && personStatus==="success")&&(
                <>
                <div className=" ml-4 flex min-w-0 gap-x-4 ">
                    <img alt="" src={person.profilePhoto? person.profilePhoto: defaultAvatar} className="h-12 w-12 flex-none rounded-full bg-gray-50" />
                    <div className="min-w-0 flex-auto items-center">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                        <a href={`/teams/${props.teamId}`}>
                        <span className="absolute inset-x-0 -top-px bottom-0" />
                        {person.name}
                        </a>
                    </p>
                    <p className="hidden mt-1 sm:flex text-xs leading-5 text-gray-500">
                        <a href={`mailto:${person.email}`} className="relative truncate hover:underline">
                        {person.email}
                        </a>
                    </p>
                    </div>
                </div>
                <div className=" mr-4 shrink-0 items-center gap-x-4">
                    <div className="flex flex-col items-center">
                    <p className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{(codes && codesStatus ==="success")?`${codes.length} Code(s) Sent`:"Loading"}</p>
                    </div>
                </div>
                <div className="pr-2 sm:pr-0 w-full " aria-hidden="true">
                <div className='flex flex-row space-x-2 items-baseline'>
                <p className='font-semibold text-green-700'>{person.finishedContent?(person.finishedContent.length*10):0}%</p>
                <p className='text-xs text-gray-500'>of Webinars Finished</p>

                </div>
                <div className="overflow-hidden rounded-full bg-gray-200">
                  <div className="h-2 rounded-full bg-green-700" style={{ width: `${person.finishedContent?(person.finishedContent.length*10):0}%` }} />
                </div>
              </div>
                <div className="hidden sm:block mr-4 shrink-0 items-center gap-x-4">
                    <div className=" flex flex-col items-center">
                    <p className="text-lg font-bold text-gray-400 leading-6">{props.teamName}</p>
                    </div>
                </div>
                </>
            )}
          </li>
    )
  }