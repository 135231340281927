import React, { useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import ToggleableOptionsSection from "./ToggleableOptionsSection";
import { updateNotifications } from "../../util/db";

export default function NotificationsSection(props) {
    const [states, setStates] = useState(props.data)
      const [allow, setAllow] = useState([]);
      const [success, setSuccess] = useState(false);
      const [disabled, setDisabled] = useState(false)
      const [exclusive, setExclusive] = useState([]);
      const [training, setTraining] = useState([]);

      const popupStyle = {
        display: 'flex',
        alignItems: 'center',
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: 'white',
        padding: '10px 20px',
        borderRadius: '5px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        border: '1px solid #28a745',
        color: '#28a745',
        fontWeight: 'bold',
        zIndex: 1000,
      };

      const showNotice = () => {
        // Simulate a save action
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000); // Hide the popup after 3 seconds
      };

    async function postData() {
        setDisabled(true)
        await updateNotifications(states.id, 
            {
                allow: allow[0],
                exclusive_email: exclusive[0],
                exclusive_sms: exclusive[1],
                training_email: training[0],
                training_sms: training[1]
            }
        )
        showNotice()
        setDisabled(false)
    }


    const handleAllow = (newToggleStates) => {
        setAllow(newToggleStates);
      };

    const handleExclusive = (newtoggleStates) => {
        setExclusive(newtoggleStates)
    }  
    const handleTraining = (newtoggleStates) => {
        setTraining(newtoggleStates)
    }  
    return (
        <div className="divide-y space-y-4">
        <ToggleableOptionsSection onToggleChange={handleAllow} options = {["Allow Notifications"]} values={[states.allow]}/>
        <div style={{pointerEvents: !allow[0]&&"none"}} className={(!allow[0])?"opacity-25":""}>
            <ToggleableOptionsSection onToggleChange={handleTraining} options = {["Email", "SMS"]} values={[states.training_email, states.training_sms]} title = "Training Updates" description = "Stay informed with notifications about the latest training sessions and updates."/>
            <ToggleableOptionsSection onToggleChange={handleExclusive} options = {["Email", "SMS"]} values={[states.exclusive_email, states.exclusive_sms]} title = "Exclusive Offers" description = "Stay informed with notifications about exclusive offers."/>
        </div>
        <div className="w-full text-center">
            <button 
            disabled = {disabled}
            onClick={() => {postData()}}
            className="m-2 px-4 py-2 bg-gray-white text-green-700 border border-solid border-green-600 rounded-md"
        >Save Changes</button>
        {success && (
        <div style={popupStyle}>
          <CheckCircleIcon className="h-6" style={{ marginRight: '8px' }} />
          Successfully saved
        </div>
      )}
        </div>
        </div>
    )
  }