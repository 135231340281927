import React, { useState, useEffect } from 'react';

const ToggleableOptionsSection = (props) => {
  // State to manage the toggle states of each option
  const [toggleStates, setToggleStates] = useState(props.values);

  useEffect(() => {
    props.onToggleChange(toggleStates);
  }, [toggleStates, props]);

  // Function to toggle the state of an option
  const toggleOption = (index) => {
    const newToggleStates = [...toggleStates];
    newToggleStates[index] = !newToggleStates[index];
    setToggleStates(newToggleStates);
  };

  return (
    <div className="space-y-4 p-4">
      <p className='text-lg font-semibold'>{props.title}</p>
      <p className='text-sm text-gray-400'>{props.description}</p>
      {props.title && (<div className='border-t w-full h-0 border-gray-200' />)}
      {props.options.map((option, index) => (
        <div key={index} className="flex justify-between items-center">
          <div>
            <div className="text-md">{option}</div>
          </div>
          {/* Toggle Button */}
          <button
            onClick={() => toggleOption(index)}
            className={`h-6 w-12 rounded-full ${toggleStates[index] ? 'bg-green-500' : 'bg-gray-400'}`}
          >
            <div
              className={`h-4 w-4 rounded-full shadow-md transform transition-transform duration-300 ${toggleStates[index] ? 'translate-x-7 bg-green-800' : 'bg-white translate-x-1'}`}
            />
          </button>
        </div>
      ))}
    </div>
  );
};

export default ToggleableOptionsSection;
