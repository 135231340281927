import React from "react"
import { useState } from "react"
import {PlusCircleIcon} from "@heroicons/react/24/solid";
import MemberAddModal from "./MemberAddModal";
export default function AddMemberCard(props) {
    const [isOpen, setisOpen] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [email, setEmail] = useState("")

    function closePopup() {
        setisOpen(false)
        setButtonDisabled(false)
        setShowModal(false)
      }

    function toggleModal() {
        setShowModal(true)
    }  

    return (
        <>
        {(isOpen)&&(
                <div className="z-20 fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
                  {/* Popup content */}
                  <div className="bg-white p-8 rounded-lg shadow-lg lg:w-1/2 w-3/4 max-w-4xl relative">
                  <button onClick={()=> closePopup()} className=" absolute top-0 right-4 text-3xl text-gray-400 rounded-md">
                      &times;
                    </button>
                    {(!showModal)?
                    <>
                    <h2 className=" text-center text-5xl font-bold pb-6">Add a Team Member</h2>

                    <div className='h-0 my-4 border-t-4 border-dotted border-gray-200'></div>
                    <p className='text-gray-400 text-left m-2'>Email</p>
                    <div className='mb-4'>
                        <label htmlFor="email" className="sr-only">
                            Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder='examplename@example.com'
                            value={email}
                            onChange={(e) => setEmail(e.target.value.toLowerCase())}
                        />
                    </div>
                    <button onClick={()=>toggleModal()} disabled={buttonDisabled} className="flex flex-row bg-green-600 text-gray-200 py-2 px-4 w-full justify-center rounded-md focus:outline-none focus:border-green-700">Add Member</button>
                    </>
                    :
                    <div>
                    <MemberAddModal team = {props.team} email = {email}/>   
                    </div>}

                  </div>
                </div>
        )}
        <button
            type="button"
            disabled={props.count <=0}
            onClick={() => {setisOpen(true)}}
            className="relative flex flex-col items-center block min-w-[280px] rounded-lg border-2 border-dashed border-green-700 p-12 text-center hover:border-green-500 focus:outline-none text-green-700 hover:text-green-500 hover:bg-green-50"
        >
            <PlusCircleIcon className="h-12 justify-center "/>
            <span className="mt-2 block text-sm font-semibold text-gray-900">Add a Team Member</span>
            <span className="mt-2 block text-sm font-semibold text-gray-400">You have {props.count} seats left.</span>

        </button>
        </>

    )
  }