import React from "react";
import Meta from "../../components/Meta";
import CalendarSection from "../../components/Custom/CalendarSection";
import { useAuth, requireAuth } from "./../../util/auth";
import SideNav from "../../components/Custom/SideNav";

function CalendarPage(props) {
  const auth = useAuth()
  return (
    <>
      <Meta title="Calendar"/>
      {auth.user&&(
        <CalendarSection uid = {auth.user.uid}/>
      )}      
      <SideNav current = "calendar"></SideNav>

    </>
  );
}

export default requireAuth(CalendarPage);
